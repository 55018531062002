import { useEffect, useRef, useState } from 'react'
import Button from '../../../../components/button/Button'
import { COLORS } from '../../../../theme/Colors'
import SummaryTable from './SummaryTable'
import {
  CancelButtonWrapper,
  CommentTableContainer,
  MarginLeftAutoSpace,
  MessageContainer,
  PopUpStyleContent,
  PopupCard,
  StyledButtonWrapper,
  StyledSelectWrapper,
  StyledTableContainer,
} from './styles'
import { Textarea } from '../../../../components/textArea/Textarea'
import { useDispatch, useSelector } from 'react-redux'
import {
  approveVoluntaryData,
  auditTrailSelector,
  commentSelector,
  corporateActionAuditTrailSelector,
  getComments,
  getCorporateActionAuditTrail,
  getUpdateApproveAuditTrail,
  getVoluntaryEventsTrackingReportData,
  putUserComment,
  updateRecord,
  voluntaryEventsReportDataSelector,
} from '../../../../store/asset-Servicing/Voluntary-Events-Tracking-Report'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import Divider from '../../../../components/divider/Divider'
import { Backgrounds, Colors } from '../../../../shared/styles'
import Table from '../../../../components/table/Table'
import { ActionExecutionButton } from '../../AssetServicingCommonComp'
import { getAccessFlag } from '../../../../utils/globalFunctions'
import SelectDropdown from '../../../../components/selectDropdown/SelectDropdown'
import { SvgEye } from '../../../../components/svg/SvgEye'

const SummaryColumns = (props: any) => {
  const dispatch = useDispatch()
  const voluntaryData = useSelector(voluntaryEventsReportDataSelector)
  const [comment, setComments] = useState<boolean>(false)
  const userComment = useSelector(commentSelector)
  const auditTrailData = useSelector(auditTrailSelector)
  const corporateActionData = useSelector(corporateActionAuditTrailSelector)
  const [inputComment, setInputComment] = useState<any>([])
  const [openComments, setOpenComments] = useState<boolean>(false)
  const [openAuditTrail, setOpenAuditTrail] = useState<boolean>(false)
  const [OpenCorporateActionAuditTrail, setOpenCorporateActionAuditTrail] =
    useState<boolean>(false)
  const [column, setColumn] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [executionUserInputs, setExecutionUserInputs] = useState<any>([])
  const [updateRef, setUpdateRef] = useState<any>()
  const [L2L3Columns, setL2L3Columns] = useState<any>([])
  const [input, setInput] = useState({
    commentRow: 0,
    commentDate: new Date().toISOString(),
  })
  const approveButtonFlag = getAccessFlag(['AA-RWX', 'OM-RWX'])

  useEffect(() => {
    const setData = async () => {
      await setTableData(props.data)
      await setExecutionUserInputs(props.data)
    }
    setData()
  }, [props.data])

  const executionUserInputsRef = useRef(executionUserInputs)
  useEffect(() => {
    executionUserInputsRef.current = executionUserInputs
  }, [executionUserInputs, props.data])

  useEffect(() => {
    setExecutionUserInputs([])
    executionUserInputsRef.current = () => {
      return []
    }
  }, [updateRef])

  const newUserComment = async (
    vetR_ID: number,
    contra: string,
    comment: string,
    createdBy: any,
    rowId: number
  ) => {
    const response = await dispatch(
      putUserComment(vetR_ID, contra, comment, createdBy)
    )
    inputComment[rowId] = '  '
    setInputComment({ ...inputComment })
  }

  const savedComments = async (tb: any) => {
    await dispatch(getComments(voluntaryData[tb].vetR_ID))
    setOpenComments(true)
  }

  const showUpdateApproveAuditTrail = async (tb: any) => {
    await dispatch(getUpdateApproveAuditTrail(voluntaryData[tb].vetR_ID))
    setOpenAuditTrail(true)
  }

  const showCorporateActionAuditTrail = async (tb: any) => {
    await dispatch(getCorporateActionAuditTrail(voluntaryData[tb].coafCaid))
    setOpenCorporateActionAuditTrail(true)
  }

  const summaryTableColumn = [
    {
      Header: 'Event',
      accessor: 'eventType',
    },
    {
      Header: 'Security',
      accessor: 'security',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Coaf/CA ID',
      accessor: 'coafCaid',
    },
    {
      Header: 'Contra',
      accessor: 'contra',
    },
    {
      Header: 'Early Deadline',
      accessor: 'earlyDeadline',
    },
    {
      Header: 'Regular Deadline',
      accessor: 'regularDeadline',
    },
    {
      Header: 'Protect Period',
      accessor: 'protectPeriod',
    },
    {
      Header: 'Client Cut Off',
      accessor: 'clientCutOff',
      objectKey: 'clientCutOff',
      Cell: ({ row, column }: any) => {
        const rowIndex = row?.index
        return (
          <input
            disabled={!approveButtonFlag || row?.original?.approveFlag}
            type={'date'}
            defaultValue={
              executionUserInputsRef.current[rowIndex]?.['clientCutOff']
                ?.toString()
                ?.split('T')[0] ?? ''
            }
            onChange={(e) => {
              setExecutionUserInputs((prevValue: any) => [
                ...prevValue.slice(0, rowIndex),
                { ...prevValue[rowIndex], ['clientCutOff']: e.target.value },
                ...prevValue.slice(rowIndex + 1),
              ])
            }}
          />
        )
      },
    },
    {
      Header: 'Elections',
      accessor: 'elections',
      objectKey: 'elections',
      Cell: ({ row, column }: any) => {
        const rowIndex = row?.index
        return (
          <SelectDropdown
            width="150px"
            defaultValue={{
              value: props.data[rowIndex]?.['elections'] ?? '',
              label: props.data[rowIndex]?.['elections'] ?? '',
            }}
            borderRadius={'4px'}
            height="32px"
            options={[
              {
                value: 'Yes',
                label: 'Yes',
              },
              {
                value: 'No',
                label: 'No',
              },
            ]}
            isDisabled={!approveButtonFlag || row?.original?.approveFlag}
            onChange={(e) => {
              setExecutionUserInputs((prevValue: any) => [
                ...prevValue.slice(0, rowIndex),
                { ...prevValue[rowIndex], ['elections']: e },
                ...prevValue.slice(rowIndex + 1),
              ])
            }}
          ></SelectDropdown>
        )
      },
    },
    {
      Header: 'Comment',
      Cell: (table: any) => {
        return (
          <>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              disabled={!approveButtonFlag}
              onClick={() => {
                setComments(!comment)
                setInput({
                  ...input,
                  commentRow: table.cell.row.id,
                })
              }}
            >
              Add Comment
            </Button>

            {comment && table.cell.row.id === input.commentRow && (
              <PopupCard>
                <Textarea
                  onChange={(e: any) => {
                    inputComment[table.cell.row.id] = e.target.value
                    setInputComment(inputComment)
                  }}
                  height="365px"
                  placeholder={'Enter Comment'}
                ></Textarea>
                <StyledButtonWrapper>
                  <Button
                    style={{
                      marginRight: '16px',
                    }}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    type={'button'}
                    color={COLORS.Background.Primarytext}
                    bgColor={'transparent'}
                    onClick={() => {
                      setComments(false)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    hoverBgColor={COLORS.Background.Primary70}
                    bgColor={COLORS.Background.Primarytext}
                    color={COLORS.Background.Primary}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    hover={true}
                    type={''}
                    activeBgColor={COLORS.Background.Primaryactive}
                    borderColor={'transparent'}
                    onClick={async () => {
                      await newUserComment(
                        voluntaryData[table.cell.row.id].vetR_ID,
                        voluntaryData[table.cell.row.id].contra,
                        inputComment[table.cell.row.id],
                        localStorage.getItem('AccountName'),
                        table.cell.row.id
                      )
                      setComments(false)
                    }}
                  >
                    Submit
                  </Button>
                </StyledButtonWrapper>
              </PopupCard>
            )}
          </>
        )
      },
    },
    {
      Header: 'Comment Audit Trail',
      Cell: (table: any) => {
        return (
          <>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                savedComments(table.cell.row.id)
              }}
            >
              View
            </Button>
          </>
        )
      },
    },
    {
      Header: 'DTCCWorkflowStatus',
      accessor: 'dtccWorkflowstatus',
    },
    {
      Header: 'Update/Approve Audit Trail',
      Cell: (table: any) => {
        return (
          <>
            <Button
              padding={'4px 16px'}
              borderRadius={'4px'}
              type={'button'}
              color={COLORS.Background.Primarytext}
              style={{ whiteSpace: 'nowrap' }}
              bgColor={'transparent'}
              onClick={() => {
                showUpdateApproveAuditTrail(table.cell.row.id)
              }}
            >
              View
            </Button>
          </>
        )
      },
    },
    {
      Header: 'Corporate Action Audit Trail',
      Cell: (table: any) => {
        return (
          <span
            role="presentation"
            title="View"
            style={{ cursor: 'pointer', width: '25px' }}
            onClick={() => {
              table.cell.row?.original?.corporateActionAuditFlag &&
                showCorporateActionAuditTrail(table.cell.row.id)
            }}
          >
            <SvgEye
              fillColor={
                table.cell.row?.original?.corporateActionAuditFlag
                  ? Colors.Black
                  : Colors.Gray30
              }
            />
          </span>
        )
      },
    },
  ]
  useEffect(() => {
    setL2L3Columns(summaryTableColumn)
  }, [props.data, comment])
  useEffect(() => {
    setColumn(summaryTableColumn)
  }, [updateRef])

  return (
    <StyledTableContainer>
      {openComments && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>View Comment</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenComments(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'Comment',
                  accessor: 'comment',
                },
                {
                  Header: 'Created by',
                  accessor: 'createdBy',
                },
                {
                  Header: 'Create on(EST)',
                  accessor: 'createdOn',
                },
              ]}
              tableData={userComment ? userComment : []}
              onClick={() => {
                //
              }}
            />
            {userComment?.length == 0 && <h3>No Comments</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
      {openAuditTrail && (
        <PopUpStyleContent>
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>View Update/Approve Audit Trail</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenAuditTrail(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'Action',
                  accessor: 'actioned',
                },
                {
                  Header: 'Updated/Approved By',
                  accessor: 'updatedorApprovedBy',
                },
                {
                  Header: 'Updated/Approved On',
                  accessor: 'updatedorApprovedOn',
                },
              ]}
              tableData={auditTrailData ? auditTrailData : []}
              onClick={() => {
                //
              }}
            />
            {auditTrailData?.length == 0 && <h3>No Comments</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
      {OpenCorporateActionAuditTrail && (
        <PopUpStyleContent
          style={{
            width: '98%',
            maxHeight: '80%',
          }}
        >
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>Corporate Action Audit Trail</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setOpenCorporateActionAuditTrail(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'Event',
                  accessor: 'event',
                },
                {
                  Header: 'Security',
                  accessor: 'security',
                },
                {
                  Header: 'Description',
                  accessor: 'description',
                },
                {
                  Header: 'Coaf/CA ID',
                  accessor: 'coafCaid',
                },
                {
                  Header: 'Contra',
                  accessor: 'contra',
                },
                {
                  Header: 'Early Deadline',
                  accessor: 'earlyDeadline',
                },
                {
                  Header: 'Regular Deadline',
                  accessor: 'regularDeadline',
                },
                {
                  Header: 'Protect Period',
                  accessor: 'protectPeriod',
                },
                {
                  Header: 'Client Cut Off',
                  accessor: 'clientCutOff',
                },
                {
                  Header: 'Elections',
                  accessor: 'elections',
                },
                {
                  Header: 'CreatedBy',
                  accessor: 'createdBy',
                },
                {
                  Header: 'CreatedOn',
                  accessor: 'createdOn',
                },
                {
                  Header: 'ModifiedBy',
                  accessor: 'modifiedBy',
                },
                {
                  Header: 'ModifiedOn',
                  accessor: 'modifiedOn',
                },
                {
                  Header: 'ReportRunDate',
                  accessor: 'reportRunDate',
                },
              ]}
              tableData={corporateActionData ? corporateActionData : []}
              onClick={() => {
                //
              }}
            />
            {corporateActionData?.length == 0 && (
              <MessageContainer>No Records Found</MessageContainer>
            )}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}

      <SummaryTable
        columns={L2L3Columns}
        data={tableData && tableData.length ? tableData : []}
        regularDeadlineFrom={props.regularDeadlineFrom}
        regularDeadlineTo={props.regularDeadlineTo}
        caidOptionList={props.caidOptionList}
        cusip={props.cusip}
        eventTypeOptionsList={props.eventTypeOptionsList}
        earlyDeadlineFrom={props.earlyDeadlineFrom}
        earlyDeadlineTo={props.earlyDeadlineTo}
        totalRecords={props.totalRecords}
        setUpdateRef={setUpdateRef}
        gotoFirstPage={props.gotoFirstPage}
        handleSearch={props.handleSearch}
        executionUserInputsRef={executionUserInputsRef}
        reOpenActions={props.reOpenActions}
      />
    </StyledTableContainer>
  )
}

export default SummaryColumns
