/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  action: string
  isLoading: boolean
  data: any
  httpErrorMessage: string
  successMessage: string
}

const initialState: State = {
  action: 'search',
  isLoading: false,
  data: [],
  httpErrorMessage: '',
  successMessage: '',
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'cais-error-report',
  initialState,
  reducers: {
    setErrorReportData: (state: State, action: PayloadAction<any>) => {
      state.data = action.payload
    },

    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  setErrorReportData,
  setIsLoading,
  setAction,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getErrorReportData: any =
  (
    action: string,
    reportDate: string,
    reportType: string,
    source: string,
    lob: string,
    errorType: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        `opsdashboardapi/OpsDashboard/GetCAISDetailedErrorReport?reportDate=${reportDate}&reportType=${reportType}&source=${source}&lob=${lob}&errorType=${errorType}`
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          setErrorReportData({
            data: data,
          })
        )

        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      dispatch(
        setErrorReportData({
          data: '',
        })
      )
      return null
    }
  }

export const getCaisErrorsCSVReport: any =
  (
    fileName: string,
    reportDate: string,
    reportType: string,
    source: string,
    lob: string | null,
    errorType: string | null
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        `opsdashboardapi/OpsDashboard/GetCAISDetailedErrorReport_csv?reportDate=${reportDate}&reportType=${reportType}&source=${source}&lob=${lob}&errorType=${errorType}`,
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.csv`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('File Downloaded Successfully'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }
export const getDashBoardLOB: any = async () => {
  try {
    const { data, status } = await authApiCall.get(
      `opsdashboardapi/OpsDashboard/Get_All_CAIS_Dashboard_LOBs`
    )
    if (status === 200) {
      return data
    }
  } catch (e: any) {
    return null
  }
}
export const ErrorReportDataSelector = (store: StoreState) =>
  store.Dashboard.errorReport.data
export const actionSelector = (store: StoreState) =>
  store.Dashboard.errorReport.action
export const LoadingSelector = (store: StoreState) =>
  store.Dashboard.errorReport.isLoading
export const HttpErrorMessageSelector = (store: StoreState) =>
  store.Dashboard.errorReport.httpErrorMessage
export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.Dashboard.errorReport.successMessage
export default reducer
