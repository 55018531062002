/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/button/Button'
import Label from '../../../components/label/Label'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import SelectDropdownWithCheckBox from '../../../components/selectDropdownWithCheckbox/SelectDropdown'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { addDays, formatISO } from 'date-fns'
import {
  getReceiveFailReport,
  loadingSelector,
  getReceiveFailReportExcel,
  receiveFailReportSelector,
  getReceiveFailReportException,
  getFTRComments,
  saveFTRComment,
  getExceptionReceiveFailReportExcel,
  getReceiveDTCCFiledReportExcel,
  getReceiveDTCCFiledReport,
  getDTCCFiledError,
  dtccErrorSelector,
  generateDTCCFile,
  HttpSuccessMessageSelector,
  HttpErrorMessageSelector,
  setHttpErrorMessage,
  setSuccessMessage,
  saveErrorSettlementsColumnsValues,
} from '../../../store/buyIns/buyInSettlements'
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledSelectWrapper,
  StyledTableContainer,
  PopUpStyleLoader,
  StyledFilterPanel,
  StyledButtonWrapper,
  LottieWrapper,
  IconsWrapper,
  PopUpSideBar,
  ErrorPopUpSideBar,
  ToastWrapper,
} from './styles'
import Table from './table/Table'
import { Textbox } from '../../../components/textbox/Textbox'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { Colors } from '../../../shared/styles'
import {
  BuyInReceiveDTCCStatusColumns,
  BuyInReceiveErrorSettlementsColumns,
  BuyInReceiveSettlementsColumns,
} from './table/tableColumns'
import { BuyInReceiveExceptionModel, TableDataModel } from './IbuyinReceive'
import Comments from '../../../composites/comments/comments'
import { AnchorTag } from '../../../components/anchorTag/AnchorTag'
import WeekDayCalender from '../../../components/WeekDayCalander/WeekDayCalender'
import { FormateDateSelector } from '../../../store/dash-board/about'
import SidePanel from '../../../composites/sidePanel/SidePanel'
import { Toast } from '../../../components/toast/Toast'
import { getAccessFlag } from '../../../utils/globalFunctions'
import BuyInReceivesAddProduct from './buyInReceives/addProduct/AddProduct'
import { SvgPlus } from '../../../components/svg/SvgPlus'
import ProductList from './buyInReceives/addProduct/ProductList'
import FtrAuditTrail from './ftrAuditTrail/FtrAuditTrail'

const BuyInReceiveSettlements = () => {
  const dispatch = useDispatch()
  const receivefailReport = useSelector(receiveFailReportSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const loading = useSelector(loadingSelector)
  const [tableCommmentsData, setTableCommmentsData] = useState<any>([])
  const commentViewReceive = useRef<HTMLInputElement>()
  const errorViewReceive = useRef<HTMLInputElement>()
  const ExcludeDate = useSelector(FormateDateSelector)
  const dtccErrorMessage = useSelector(dtccErrorSelector)
  const [input, setInput] = useState({
    typeCategory: '',
    settleDateFrom: null,
    settleDateTo: null,
    securityCategory: '',
    symbol: '',
    cusip: '',
    quantity: 0,
    accountNumber: '',
    broker: '',
    pageSize: 10,
    pageNumber: 1,
  })

  const [columnValue, setColumnValue] = useState<any>([])

  const [tableData, setTableData] = useState<any>([])
  const [executionUserInputs, setExecutionUserInputs] = useState<any>([])
  const [updateRef, setUpdateRef] = useState<any>()
  const [checkFilter, setCheckFilter] = useState<any>({
    valid: true,
    exceptional: false,
    dtccFiled: false,
  })
  const [toggleSaveFix, setToggleSaveFix] = useState<boolean>(true)
  const [gotoFirstPage, setGotoFirstPage] = useState<boolean>(true)
  const [recordWarningMsg, setRecordWarningMsg] = useState<string>('')

  useEffect(() => {
    const setData = async () => {
      await setTableData(receivefailReport.receiveFailReportModel)
      await setExecutionUserInputs(receivefailReport.receiveFailReportModel)
    }
    setData()
  }, [receivefailReport])

  const executionUserInputsRef = useRef(executionUserInputs)
  useEffect(() => {
    executionUserInputsRef.current = executionUserInputs
  }, [executionUserInputs])
  const [column, setColumn] = useState<any>([])

  useEffect(() => {
    setExecutionUserInputs([])
    executionUserInputsRef.current = () => {
      return []
    }
  }, [updateRef, checkFilter.exceptional, toggleSaveFix])

  const [sourceOption, setSourceOption] = useState<any>(null)
  const [securityCategoryOption, setSecurityCategoryOption] = useState<any>()
  const [ftrRowData, setFTRData] = useState<any>()
  const [typeOption, setTypeOption] = useState<string>('Daily')
  const [status, setStatus] = useState<any>('')
  const [calendar, setCalendar] = useState<boolean>(false)
  const [endCalendar, setEndCalender] = useState<boolean>(false)
  const DefaultDate: string =
    localStorage.getItem('DefaultDate') &&
    localStorage.getItem('DefaultDate') != 'undefined'
      ? JSON.parse(localStorage.getItem('DefaultDate') || '')
      : ''
  const [jobRunDate, setJobRunDate] = useState<any>(
    DefaultDate ? new Date(DefaultDate) : ''
  )
  const [jobRunEndDate, setJobRunEndDate] = useState<any>(
    DefaultDate ? new Date(DefaultDate) : ''
  )

  const [toggleComments, SetToggleComments] = useState<boolean>(false)
  const [toggleAuditTrail, SetToggleAuditTrail] = useState<boolean>(false)
  const [toggleError, SetToggleError] = useState<boolean>(false)
  const [showProductList, setShowProductList] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const writeExecuteFlag = getAccessFlag(['SS-RWX', 'OM-RWX'])
  const toggleLabels = ['Valid', 'Invalid', 'DTCC Submitted']

  const sourceValues = [
    {
      value: '',
      label: '--Select--',
    },
    {
      value: 'Beta',
      label: 'BETA',
    },
    {
      value: 'P3',
      label: 'P3',
    },
  ]

  const typeCategory = [
    {
      value: 'Daily',
      label: 'Daily',
    },
    {
      value: 'Historical-Large File',
      label: 'Historical-Large File',
    },
  ]

  const statusOption = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'New Notice Created',
      label: 'New Notice Created',
    },
    {
      value: 'Accepted',
      label: 'Accepted',
    },
    {
      value: 'Updated',
      label: 'Updated',
    },
    {
      value: 'Cancelled',
      label: 'Cancelled',
    },
    {
      value: 'Delivery Date Extended',
      label: 'Delivery Date Extended',
    },
    {
      value: 'Created',
      label: 'Created',
    },
    {
      value: 'Error',
      label: 'Error',
    },
    {
      value: 'Submitted',
      label: 'Submitted',
    },
    {
      value: 'Transmitted',
      label: 'Transmitted',
    },
    {
      value: 'Rejected',
      label: 'Rejected',
    },
    {
      value: 'Failed',
      label: 'Failed',
    },
    {
      value: 'Timed-Out',
      label: 'Timed Out',
    },
  ]
  const securityCategory = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'Equity',
      label: 'Equity',
    },
    {
      value: 'FixedIncome',
      label: 'Fixed Income',
    },
  ]
  const counterpartyType = [
    {
      value: 'Broker',
      label: 'Broker',
    },
    {
      value: 'DVP_Customer',
      label: 'DVP Customer',
    },
    {
      value: 'CUSTOMER',
      label: 'Customer',
    },
    {
      value: 'Suspense',
      label: 'Suspense',
    },
  ]
  const [counterParty, setCounterParty] = useState<any>([
    {
      value: 'Broker',
      label: 'Broker',
    },
    {
      value: 'DVP_Customer',
      label: 'DVP Customer',
    },
  ])

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (
        commentViewReceive?.current &&
        !commentViewReceive?.current?.contains(event.target)
      ) {
        SetToggleComments(false)
        SetToggleAuditTrail(false)
      }
      if (
        errorViewReceive?.current &&
        !errorViewReceive?.current?.contains(event.target)
      ) {
        SetToggleError(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside, {
      capture: true,
    })
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside, {
        capture: true,
      })
    }
  }, [commentViewReceive, errorViewReceive])

  const setCommentTable = async (select_row: BuyInReceiveExceptionModel) => {
    const getCommentsData = await dispatch(
      getFTRComments(select_row.ftrUniqueId)
    )
    if (
      getCommentsData &&
      getCommentsData.settlementHistoricCommentsModelList
    ) {
      setTableCommmentsData([
        ...getCommentsData.settlementHistoricCommentsModelList,
      ])
    }
  }

  const getError = async (select_row: BuyInReceiveExceptionModel) => {
    if (!toggleError) {
      await dispatch(getDTCCFiledError(select_row?.id))
    }
    SetToggleError(!toggleError)
  }

  const setPageCount = async (pageIndex: number, rowRange: number) => {
    setInput({
      ...input,
      pageNumber: pageIndex,
      pageSize: rowRange,
    })
    const counterPartyArray: string[] = []
    if (counterParty && counterParty.length > 0) {
      counterParty.forEach((element: any) => {
        counterPartyArray?.push(element.value)
      })
    }
    if (checkFilter.valid) {
      await dispatch(
        getReceiveFailReport(
          securityCategoryOption != '' ? securityCategoryOption : null,
          sourceOption,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.quantity ? input.quantity : null,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          rowRange,
          pageIndex
        )
      )
    } else if (checkFilter.exceptional) {
      await dispatch(
        getReceiveFailReportException(
          securityCategoryOption != '' ? securityCategoryOption : null,
          sourceOption,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.quantity ? input.quantity : null,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          rowRange,
          pageIndex
        )
      )
    } else if (checkFilter.dtccFiled) {
      await dispatch(
        getReceiveDTCCFiledReport(
          securityCategoryOption != '' ? securityCategoryOption : null,
          sourceOption,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.quantity ? input.quantity : null,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          status.trim() === '' ? null : status,
          rowRange,
          pageIndex
        )
      )
    }
  }
  const getDetails = async (filterValue: string) => {
    const counterPartyArray: string[] = []
    if (counterParty && counterParty.length > 0) {
      counterParty.forEach((element: any) => {
        counterPartyArray?.push(element.value)
      })
    }
    if (filterValue === 'valid') {
      await dispatch(
        getReceiveFailReport(
          securityCategoryOption != '' ? securityCategoryOption : null,
          sourceOption,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.quantity ? input.quantity : null,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          10,
          1
        )
      )
    } else if (checkFilter.exceptional) {
      await dispatch(
        getReceiveFailReportException(
          securityCategoryOption != '' ? securityCategoryOption : null,
          sourceOption,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.quantity ? input.quantity : null,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          10,
          1
        )
      )
    } else if (checkFilter.dtccFiled) {
      await dispatch(
        getReceiveDTCCFiledReport(
          securityCategoryOption != '' ? securityCategoryOption : null,
          sourceOption,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.quantity ? input.quantity : null,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          status.trim() === '' ? null : status,
          10,
          1
        )
      )
    }
  }

  const handleSearch = async () => {
    setToggleSaveFix(!toggleSaveFix)
    setGotoFirstPage(!gotoFirstPage)
    setInput({
      ...input,
      pageSize: 10,
      pageNumber: 1,
    })
    const counterPartyArray: string[] = []
    if (counterParty && counterParty.length > 0) {
      counterParty.forEach((element: any) => {
        counterPartyArray?.push(element.value)
      })
    }
    if (checkFilter.valid) {
      await dispatch(
        getReceiveFailReport(
          securityCategoryOption != '' ? securityCategoryOption : null,
          sourceOption,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.quantity ? input.quantity : null,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          10,
          1
        )
      )
    } else if (checkFilter.exceptional) {
      await dispatch(
        getReceiveFailReportException(
          securityCategoryOption != '' ? securityCategoryOption : null,
          sourceOption,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.quantity ? input.quantity : null,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          10,
          1
        )
      )
    } else if (checkFilter.dtccFiled) {
      await dispatch(
        getReceiveDTCCFiledReport(
          securityCategoryOption != '' ? securityCategoryOption : null,
          sourceOption,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.quantity ? input.quantity : null,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          status.trim() === '' ? null : status,
          10,
          1
        )
      )
    }
  }

  const toggleViewComments = async (select_row: BuyInReceiveExceptionModel) => {
    setFTRData(select_row)
    setComment('')
    // if (!toggleComments) {
    //   await setCommentTable(select_row)   // add comment
    // }
    // SetToggleComments(!toggleComments)
    if (!toggleAuditTrail) {
      await setCommentTable(select_row)
    }
    SetToggleAuditTrail(!toggleAuditTrail)
  }

  const saveColumnValues = async (
    id: number,
    selectedRow: any,
    saveFixFlag: number
  ) => {
    const secTypeComment =
      (!selectedRow?.original?.secType ||
        !selectedRow?.original?.secType?.trim()) &&
      executionUserInputsRef?.current[id]?.secType
        ? 'Sec Type changed to ' + executionUserInputsRef?.current[id]?.secType
        : ''
    const delvBrokerComment =
      (!selectedRow?.original?.delvBroker ||
        !selectedRow?.original?.delvBroker?.trim()) &&
      executionUserInputsRef?.current[id]?.delvBroker
        ? 'Delivery Broker changed to ' +
          executionUserInputsRef.current[id]?.delvBroker
        : ''

    const response = await dispatch(
      saveErrorSettlementsColumnsValues(
        selectedRow?.original?.ftrUniqueId.toString(),
        selectedRow?.original?.secType &&
          selectedRow?.original?.delvBroker?.trim() != ''
          ? selectedRow?.original?.secType
          : executionUserInputsRef?.current[id]?.secType,
        selectedRow?.original?.delvBroker &&
          selectedRow?.original?.delvBroker?.trim() != ''
          ? selectedRow?.original?.delvBroker
          : executionUserInputsRef?.current[id]?.delvBroker,
        selectedRow?.original?.source,
        saveFixFlag,
        `${secTypeComment}
        ${delvBrokerComment}`
      )
    )
    setPageCount(input.pageNumber, input.pageSize)
    setToggleSaveFix(!toggleSaveFix)
  }

  useEffect(() => {
    setColumn(
      BuyInReceiveErrorSettlementsColumns(
        toggleViewComments,
        saveColumnValues,
        executionUserInputsRef,
        setExecutionUserInputs
      )
    )
  }, [updateRef, checkFilter.exceptional, toggleSaveFix])

  const downloadExcel = async () => {
    const counterPartyArray: any = []
    if (counterParty && counterParty.length > 0) {
      counterParty.forEach((element: any) => {
        counterPartyArray.push(element.value)
      })
    }
    const source = sourceOption === '' ? null : sourceOption
    if (checkFilter.valid) {
      await dispatch(
        getReceiveFailReportExcel(
          `${source != null ? sourceOption : ''}ReceiveFailReport_${
            formatISO(jobRunDate).split('T')[0]
          }`,
          securityCategoryOption != '' ? securityCategoryOption : null,
          sourceOption,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.quantity ? input.quantity : null,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          null,
          0,
          0
        )
      )
    } else if (checkFilter.exceptional) {
      await dispatch(
        getExceptionReceiveFailReportExcel(
          `${source != null ? sourceOption : ''}ExceptionReceiveFailReport_${
            formatISO(jobRunDate).split('T')[0]
          }`,
          securityCategoryOption != '' ? securityCategoryOption : null,
          sourceOption,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.quantity ? input.quantity : null,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          null,
          0,
          0
        )
      )
    } else if (checkFilter.dtccFiled) {
      await dispatch(
        getReceiveDTCCFiledReportExcel(
          `${source != null ? sourceOption : ''}DTCCFiledReport_${
            formatISO(jobRunDate).split('T')[0]
          }`,
          formatISO(jobRunDate).split('T')[0],
          typeOption === 'Daily'
            ? formatISO(jobRunDate).split('T')[0]
            : formatISO(jobRunEndDate).split('T')[0],
          sourceOption,
          securityCategoryOption != '' ? securityCategoryOption : null,
          input.cusip.trim() === '' ? null : input.cusip,
          input.symbol.trim() === '' ? null : input.symbol,
          input.accountNumber.trim() === '' ? null : input.accountNumber,
          input.quantity ? input.quantity : null,
          input.broker.trim() === '' ? null : input.broker,
          counterPartyArray && counterPartyArray.length > 0
            ? counterPartyArray.toString()
            : null,
          null,
          status.trim() === '' ? null : status,
          0,
          0
        )
      )
    }
  }

  const handleDTCCFTRFileGeneration = async () => {
    const counterPartyArray: string[] = []
    if (counterParty && counterParty.length > 0) {
      counterParty.forEach((element: any) => {
        counterPartyArray?.push(element.value)
      })
    }
    await dispatch(
      generateDTCCFile(
        securityCategoryOption != '' ? securityCategoryOption : null,
        sourceOption,
        formatISO(jobRunDate).split('T')[0],
        typeOption === 'Daily'
          ? formatISO(jobRunDate).split('T')[0]
          : formatISO(jobRunEndDate).split('T')[0],
        input.cusip.trim() === '' ? null : input.cusip,
        input.symbol.trim() === '' ? null : input.symbol,
        input.quantity ? input.quantity : null,
        input.accountNumber.trim() === '' ? null : input.accountNumber,
        input.broker.trim() === '' ? null : input.broker,
        counterPartyArray && counterPartyArray.length > 0
          ? counterPartyArray.toString()
          : null
      )
    )
  }

  const filterOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput({
      ...input,
      pageNumber: 1,
      pageSize: 10,
    })
    setGotoFirstPage(!gotoFirstPage)

    const { id, checked } = e.target
    switch (id) {
      case 'valid': {
        checkFilter[id] = checked
        checkFilter.exceptional = false
        checkFilter.dtccFiled = false
        setCheckFilter({ ...checkFilter })
        getDetails('valid')
        break
      }
      case 'exceptional': {
        checkFilter[id] = checked
        checkFilter.valid = false
        checkFilter.dtccFiled = false
        setCheckFilter({ ...checkFilter })
        getDetails('exceptional')
        break
      }
      case 'dtccFiled': {
        checkFilter[id] = checked
        checkFilter.valid = false
        checkFilter.exceptional = false
        setCheckFilter({ ...checkFilter })
        getDetails('dtccFiled')
        break
      }
    }
  }

  const commentsOnChange = (e: any) => {
    const { value } = e.target
    setComment(value)
  }

  const commentTrade = async () => {
    const request = {
      actionId: ftrRowData.ftrUniqueId,
      comments: comment,
    }
    await dispatch(saveFTRComment(request))
    setComment('')
    await setCommentTable(ftrRowData)
  }

  return (
    <RootContainer>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <StyledPanelHead>
        <StyledPanelHeadTitle>
          Buy-In Intents Fails To Receive
        </StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
        <StyledFilterPanel>
          <StyledSelectWrapper>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Source
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={sourceValues}
                onChange={(options) => {
                  if (options != '') setSourceOption(options)
                  else setSourceOption(null)
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Type
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={typeCategory}
                onChange={(options) => {
                  setTypeOption(options)
                }}
                defaultValue={{
                  value: 'Daily',
                  label: 'Daily',
                }}
              />{' '}
            </div>
            <div>
              {typeOption == 'Daily' ? (
                <>
                  <Label
                    fontSize="16px"
                    fontWeight={400}
                    color={'#0F172A'}
                    lineHeight={'24px'}
                  >
                    Select Settle Date
                    <span style={{ color: 'red' }}>*</span>
                  </Label>
                </>
              ) : (
                <Label
                  fontSize="16px"
                  fontWeight={400}
                  color={'#0F172A'}
                  lineHeight={'24px'}
                >
                  Select Settle FromDate
                  <span style={{ color: 'red' }}>*</span>
                </Label>
              )}
              <WeekDayCalender
                onSelect={() => setCalendar(false)}
                excludeDates={ExcludeDate}
                open={calendar}
                onFocus={() => setCalendar(true)}
                onClickOutside={() => setCalendar(false)}
                selected={jobRunDate}
                maxDate={addDays(new Date(), -1)}
                onChange={(e: Date) => {
                  setJobRunDate(e)
                }}
              />
            </div>
            {typeOption == 'Historical-Large File' && (
              <div>
                <Label
                  fontSize="16px"
                  fontWeight={400}
                  color={'#0F172A'}
                  lineHeight={'24px'}
                >
                  Select Settle ToDate
                  <span style={{ color: 'red' }}>*</span>
                </Label>
                <WeekDayCalender
                  onSelect={() => setEndCalender(false)}
                  excludeDates={ExcludeDate}
                  open={endCalendar}
                  onFocus={() => setEndCalender(true)}
                  onClickOutside={() => setEndCalender(false)}
                  selected={jobRunEndDate}
                  minDate={jobRunDate}
                  maxDate={addDays(new Date(), -1)}
                  onChange={(e: Date) => {
                    setJobRunEndDate(e)
                  }}
                />
              </div>
            )}

            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Symbol
              </Label>
              <Textbox
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
                onChange={(e: string) => {
                  setInput({ ...input, symbol: e })
                }}
                placeholder={'SYMBOL'}
                value={input.symbol}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                CUSIP
              </Label>
              <Textbox
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
                onChange={(e: string) => {
                  setInput({ ...input, cusip: e })
                }}
                placeholder={'CUSIP'}
                value={input.cusip}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Quantity
              </Label>
              <Textbox
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
                type={'number'}
                onChange={(e: any) => {
                  setInput({ ...input, quantity: e })
                }}
                placeholder={'Quantity'}
                // value={input.quantity}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Account Number
              </Label>
              <Textbox
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
                onChange={(e: string) => {
                  setInput({ ...input, accountNumber: e })
                }}
                placeholder={'Account Number'}
                value={input.accountNumber}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Broker
              </Label>
              <Textbox
                style={{
                  borderRadius: '4px',
                  height: '32px',
                  padding: '4px 8px',
                }}
                onChange={(e: string) => {
                  setInput({ ...input, broker: e })
                }}
                placeholder={'Broker'}
                value={input.broker}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                CounterParty Type
              </Label>
              <SelectDropdownWithCheckBox
                height="32px"
                buttonWidth="300px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                isMulti={true}
                options={counterpartyType}
                optionValues={counterpartyType}
                value={counterParty}
                onChange={(options) => {
                  setCounterParty(options)
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
                labelValue={'Counter Party'}
                allowSelectAll={true}
                whiteSpace={'nowrap'}
                overflow={'hidden'}
                flexWrap={'nowrap'}
              />
            </div>
            {checkFilter.dtccFiled && (
              <div>
                <Label
                  fontSize="16px"
                  fontWeight={400}
                  color={'#0F172A'}
                  lineHeight={'24px'}
                >
                  Status
                </Label>
                <SelectDropdown
                  height="32px"
                  buttonWidth="150px"
                  padding="4px 16px"
                  borderRadius="4px"
                  fw={100}
                  lh="24px"
                  fs="16px"
                  options={statusOption}
                  onChange={(options) => {
                    setStatus(options)
                  }}
                  defaultValue={{
                    value: '',
                    label: '--Select--',
                  }}
                />{' '}
              </div>
            )}
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Security Category
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={securityCategory}
                onChange={(options) => {
                  setSecurityCategoryOption(options)
                  // getSourceValue(options)
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <StyledButtonWrapper>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                onClick={() => {
                  handleSearch()
                }}
                borderColor={'#2563EB'}
              >
                Search
              </Button>
            </StyledButtonWrapper>
          </StyledSelectWrapper>
        </StyledFilterPanel>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignContent: 'center',
            gap: '4px',
            marginTop: '10px',
          }}
        >
          {checkFilter.valid && (
            <Button
              hoverBgColor={'#FFFFFF'}
              hoverTextColor={'#2563EB'}
              borderRadius={'4px'}
              disabled={!writeExecuteFlag}
              hover={true}
              padding={'4px 16px'}
              bgColor={'#2563EB'}
              color={'White'}
              type={'Button'}
              onClick={() => {
                receivefailReport?.totalCount > 110
                  ? setRecordWarningMsg(
                      'Record count exceed more than 110, please proceed in batches'
                    )
                  : handleDTCCFTRFileGeneration()
              }}
              borderColor={'#2563EB'}
            >
              {'Generate DTCC FTR File'}
            </Button>
          )}
          <IconsWrapper
            onClick={() => {
              writeExecuteFlag && setShowProductList(true)
            }}
          >
            <SvgPlus fillColor={Colors.PrimaryText}></SvgPlus>
            <Label color={Colors.PrimaryText}>Add Product</Label>
          </IconsWrapper>

          <IconsWrapper onClick={() => downloadExcel()}>
            <SvgDownloadSmall fillColor={Colors.PrimaryText}></SvgDownloadSmall>
            <Label color={Colors.PrimaryText}>Download</Label>
          </IconsWrapper>
          <IconsWrapper>
            <AnchorTag
              href={'/FTR.pdf'}
              download={true}
              title={'HelpDoc'}
              color={Colors.PrimaryText}
              textDecoration={'none'}
              target={'_blank'}
              icon={<SvgDownloadSmall fillColor={Colors.PrimaryText} />}
            />
          </IconsWrapper>
        </div>
        <StyledTableContainer>
          <Table
            setPageCount={setPageCount}
            hidePagination={false}
            heading={'Fails To Receive'}
            data={tableData && tableData.length ? tableData : []}
            totalCount={
              receivefailReport?.totalCount ? receivefailReport?.totalCount : 0
            }
            columns={
              checkFilter.valid
                ? BuyInReceiveSettlementsColumns(toggleViewComments)
                : checkFilter.exceptional
                ? column
                : BuyInReceiveDTCCStatusColumns(getError)
            }
            isFilter="true"
            checkFilter={checkFilter}
            checkFilterKeys={['valid', 'exceptional', 'dtccFiled']}
            filterOnChange={filterOnChange}
            toggleLabels={toggleLabels}
            setUpdateRef={setUpdateRef}
            gotoFirstPage={gotoFirstPage}
          />
        </StyledTableContainer>

        {toggleAuditTrail && (
          <PopUpSideBar ref={commentViewReceive}>
            <FtrAuditTrail
              tableCommmentsData={tableCommmentsData}
              commentsOnChange={commentsOnChange}
              comment={comment}
              commentTrade={commentTrade}
              ftrRowData={ftrRowData}
            />
          </PopUpSideBar>
        )}

        {toggleComments && (
          <PopUpSideBar ref={commentViewReceive}>
            <Comments
              tableCommmentsData={tableCommmentsData}
              commentsOnChange={commentsOnChange}
              comment={comment}
              commentTrade={commentTrade}
              ftrRowData={ftrRowData}
              writeExecuteFlag={writeExecuteFlag}
            />
          </PopUpSideBar>
        )}

        {showProductList && (
          <ProductList setShowProductList={setShowProductList} />
        )}

        {toggleError && (
          <ErrorPopUpSideBar ref={errorViewReceive}>
            <SidePanel
              title={'FTR Error List'}
              messages={dtccErrorMessage ? dtccErrorMessage : []}
              buttonDetails={[
                {
                  buttonText: 'Close',
                  onClick: () => {
                    SetToggleError(false)
                  },
                },
              ]}
              onCancelClick={() => {
                SetToggleError(false)
              }}
            ></SidePanel>
          </ErrorPopUpSideBar>
        )}
      </StyledPanelSelect>
      {(SuccessMessage?.length > 0 ||
        ErrorMessage?.length > 0 ||
        recordWarningMsg) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage || recordWarningMsg}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
              setRecordWarningMsg('')
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}
export default BuyInReceiveSettlements
