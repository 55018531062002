import { useEffect, useState } from 'react'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Table from '../../../components/table/Table'
import { COLORS } from '../../../theme/Colors'
import { PopUpStyleContent } from '../styles'
import { shortSalesDetailsColumns } from './BetaShortSaleColumn'
import { useSelector } from 'react-redux'
import { dataSelector } from '../../../store/reg-SHO-Tool/Reg-SHO-Summary'
import { authApiCall } from '../../../utils/api'

const BetaShortSalePopUp = ({ setShortSaleTable, selectedRowId }: any) => {
  const selectedData = useSelector(dataSelector)
  const [data, setData] = useState([{}])
  useEffect(() => {
    async function getShortSaleData() {
      const response = await authApiCall.post(
        `opsfailprocessapi/BuyInIntents/GetRegShoBetaShortSaleNet`,
        {
          cusip: selectedData.data[selectedRowId].cusiP_Number,
          symbol: selectedData.data[selectedRowId].symbol.trimEnd(),
          orderDate: selectedData.data[selectedRowId].reportDate,
          net: selectedData.data[selectedRowId].shortSaleTradeNet,
        }
      )
      console.log(response)
      setData(response.data?.betaShortSaleTradeModel)
    }
    getShortSaleData()
  }, [])

  return (
    <PopUpStyleContent style={{ height: '60%', width: '93%' }}>
      <div
        style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px' }}
      >
        <h2>RegSho Beta ShortSaleNet Table</h2>
        <span
          role="button"
          tabIndex={0}
          onClick={() => {
            setShortSaleTable(false)
          }}
          onKeyDown={(event) => {
            //
          }}
          style={{ marginLeft: 'auto', cursor: 'pointer' }}
        >
          <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
        </span>
      </div>
      <hr />
      <div style={{ overflow: 'auto' }}>
        <Table
          tableColumns={shortSalesDetailsColumns()}
          tableData={data}
          onClick={() => {
            //
          }}
        />
      </div>
      {data.length === 0 && (
        <h3 style={{ display: 'flex', justifyContent: 'center' }}>
          No data available for the selected row
        </h3>
      )}
    </PopUpStyleContent>
  )
}

export default BetaShortSalePopUp
