import styled from 'styled-components'

export interface AlertProps {
  border?: string
  background?: string
  fontColor?: string
  width?: string
}

export interface TableProps {
  minHeight?: string
  fontSize?: string
}

export const StyledNoData = styled.div`
  text-align: center;
  font-size: 12px;
  padding: 2px;
`
export const WidgetContainer = styled.div`
  border: 1px solid black;
  height: fit-content;
  padding: 20px;
`

export const LottieWrapper = styled.div`
  // position: fixed;
  // top: 70%;
  // left: 50%;
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  text-align: center;
  background-color: url(.png);
`

export const TitleContainer = styled.div`
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding: 12px 5px;
`

export const TableChartWidgetContainerByAgeEquity = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 0px 20px 20px 20px;
  grid-column: 1/5;
  grid-row: 1/3;
`

export const TableChartWidgetContainerByFI = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 0px 20px 20px 20px;
  grid-column: 5/9;
  grid-row: 1/3;
`
export const TableChartWidgetContainerByTrend = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 20px 20px 0px 20px;
  grid-column: 1/9;
  grid-row: 3/7;
`
export const TableChartWidgetContainerByMpid = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 0px 20px 20px 20px;
  grid-column: 1/5;
  grid-row: 1/3;
`

export const SummaryCardContainer = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  height: fit-content;
  padding: 20px;
`
export const ChartContainerByAgeEquity = styled.div`
  height: fit-content;
  width: 100%;
  align-item: center;
  // padding: 25px;
`
export const ChartContainerByTradeBreakByAgeFI = styled.div`
  height: fit-content;
  width: 100%;
  align-item: center;
  // padding: 0px;
`
export const ChartContainerByMpid = styled.div`
  height: fit-content;
  width: 100%;
  align-item: center;
  // padding: 25px;
`

export const SummaryCardContainerSuspenseAccount = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 8px;
  grid-column: 9/12;
  grid-row: 3/4;
  position: relative;
  // opacity:0.4;
`
export const SummaryCardContainerTradeBreakCountT0 = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 10px;
  grid-column: 9/12;
  grid-row: 1/2;
  position: relative;
`
export const SummaryCardContainerTradeBreakCountT1 = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 8px;
  grid-column: 9/12;
  grid-row: 2/3;
  position: relative;
`
export const SummaryCardContainerTradeBreaksTrend = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 20px;
  grid-column: 2/5;
  grid-row: 5/7;
  position: relative;
`
export const SummaryCardContainerTradeBreaksAlerts = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 8px;
  grid-column: 9/12;
  grid-row: 3/7;
  position: relative;
`
export const SummaryCardContainerErrorCodeDec = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 8px;
  grid-column: 1/12;
  grid-row: 7/7;
  position: relative;
`
export const SummaryCardContainerFDIDErrorCode = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 8px;
  grid-column: 1/6;
  grid-row: 8/9;
  position: relative;
`
export const SummaryCardContainerCustomerErrorCode = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(0, 36, 93, 0.3);
  padding: 8px;
  grid-column: 7/12;
  grid-row: 8/9;
  position: relative;
`
export const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 2%;
  margin-top: 2%;
`
export const StyledTableContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding: 15px;
  background: #fcfdff;
  border: 1px solid #d0d7e2;
  box-sizing: border-box;
  overflow-x: hidden;
  box-shadow: 0px 8px 16px -8px rgba(0, 36, 93, 0.3);
  border-radius: 8px 8px 8px 8px;
`
export const RootContainerTable = styled.div<TableProps>`
  table {
    width: 100%;

    th {
      font-style: normal;
      font-weight: 700;
      cursor: pointer;
      font-size: 12px;
      line-height: 16px;
      color: black;
      text-align: left;
      border-radius: 1px;
      padding: 4px 4px;
      border-right: 1px solid #d0d7e2;
      border-bottom: 1px solid #d0d7e2;
      &:active {
        box-shadow: inset 0 3px 0 0 rgb(0 0 0 / 60%);
      }
    }

    tr {
      td {
        padding: 2px 5px;
        color: black;
        font-style: normal;
        font-weight: 400;
        font-size: ${(props: TableProps) =>
          props.fontSize ? props.fontSize : '14px'};
        line-height: 24px;
        text-align: left;
        box-shadow: inset 0px -1px 0px #d0d7e2;
      }

      td:first-child {
        text-align: left;
      }
    }
  }
  grid-row: 2/3;
  justify-items: center;
  min-height: ${(props: TableProps) =>
    props.minHeight ? props.minHeight : '170px'};
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 300px;
  height: 250px;
  background-color: white;
  border: 1px solid black;
`

export const ChartContainer = styled.div`
  width: fit-content;
  // height: 100%;
  margin-left: 2%;
  border: none;
  height: fit-content;
  padding: 20px;
`
export const InformationTile = styled.div`
  padding: 20px 10px;
  border-radius: 8px;
  border: 2px solid #e2e8f0;
  background: #f8fafc;
  width: 48%;
  cursor: pointer;
`

export const TitleSubContainer = styled.div`
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding: 12px 5px;
`

export const AlertTile = styled.div<AlertProps>`
  padding: 4px;
  border-radius: 8px;
  border: ${(props: AlertProps) => props.border};
  background: ${(props: AlertProps) => props.background};
  width: ${(props: AlertProps) => props.width};
  font-color: ${(props: AlertProps) => props.fontColor};
`

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const VerticalAlertsContainer = styled.div`
  display: flex;
  gap: 2%;
`
export const AlertLabel = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: black;
`
export const ParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const InformationContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: 10px;
`

export const QuantityContainer = styled.p`
  color: #475569;
  font-size: 14px;
  margin-bottom: 8px;
`

export const AmountContainer = styled.span`
  font-weight: 600;
  font-size: 16px;
`
export const AlertDataContainer = styled.span`
  display: block;
  text-align: left;
  font-weight: 600;
  font-size: 26px;
  padding: 10px;
  text-align-last: center;
  cursor: pointer;
`
export const DangerArrowContainer = styled.span`
  background: #fef1ee;
  border: 1px solid #feb8ab;
  border-radius: 8px;
  padding: 5px;
`
export const ArrowContainer = styled.span`
  background: #ebf7e6;
  border: 1px solid #91db8b;
  border-radius: 8px;
  padding: 5px;
`

export const EqualContainer = styled.span`
  background: #bfdbfe;
  border: 1px solid #60a5fa;
  border-radius: 8px;
  padding: 5px;
  margin: 10px;
`
export const DropDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-left: 15px;
`
export const PopUpStyleLoader = styled.div`
  background: #f0f2f5;
  position: fixed;
  height: 20%;
  width: 15%;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 10px 15px 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 150rem 150rem rgba(0, 36, 93, 0.3);
  border-radius: 8px;
`
export const BodyContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 5px;
`
