/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ButtonWrapper,
  CardWrapper,
  LabelAmount,
  LabelHeader,
  LottieWrapper,
  PopUpStyleLoader,
  StyledSelectWrapper,
  StyledTableContainer,
  ToastWrapper,
} from './styles'
import Loader from '../../../assets/lottiefiles/loader.json'
import ToolSummaryTable from './toolSummaryTable/ToolSummaryTable'
import { useDispatch, useSelector } from 'react-redux'
import WDCard from '../../../components/ui/WDCard'
import {
  LoadingSelector,
  TotalCountSelector,
  dataSelector,
  getRegSHOSummaryData,
  getRegShoSummaryLevelExcelReportForP3,
  getRegSHOTimeStamp,
  sortDirectionSelector,
  sortingSelector,
  saveActionedOnChanges,
  setToastMessage,
  ToastMessageSummarySelector,
  DTCCTimeSelector,
} from '../../../store/reg-SHO-Tool/Reg-SHO-Summary'
import { useEffect, useState } from 'react'
import { CommonTabProps } from '../RegSHOModels'
import {
  DtccTable,
  // ToolSummaryColumns,
  getToolSummaryColumns,
} from './toolSummaryTable/ToolSummaryColumns'
import Lottie from 'lottie-react'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { COLORS } from '../../../theme/Colors'
import {
  WDStyledButtonText,
  WDStyledCardHeading,
} from '../../../components/ui/WDTypography'
import Label from '../../../components/label/Label'
import { Toast } from '../../../components/toast/Toast'
import {
  CancelButtonWrapper,
  MarginLeftAutoSpace,
  PopUpStyleContent,
} from '../styles'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Divider from '../../../components/divider/Divider'
import { Backgrounds } from '../../../shared/styles'
import Table from '../../../components/table/Table'
import { Link } from 'react-router-dom'
import BetaShortSalePopUp from './BetaShortSalePopUp'
interface IActionedOnItem {
  id: number
  value: boolean
}
interface RegSHOSummaryDetailsProps {
  commonTabProps: CommonTabProps
  setCommonTabProps: React.Dispatch<React.SetStateAction<any>>
  setActiveTab: React.Dispatch<React.SetStateAction<any>>
  filterData?: { id: string; value: string }[]
  setSummaryFilters?: React.Dispatch<React.SetStateAction<any>>
}
const ToolSummaryDetails = ({
  commonTabProps,
  setCommonTabProps,
  setActiveTab,
  filterData,
  setSummaryFilters,
}: RegSHOSummaryDetailsProps) => {
  const dispatch = useDispatch()
  const data = useSelector(dataSelector)
  const DTCCTime = useSelector(DTCCTimeSelector)
  const message = useSelector(ToastMessageSummarySelector)
  const [showTimeStamp, setShowTimeStamp] = useState<boolean>(false)
  const loading = useSelector(LoadingSelector)
  const sorting = useSelector(sortingSelector)
  const sortDirection = useSelector(sortDirectionSelector)
  const TotalCount = useSelector(TotalCountSelector)
  const {
    source,
    fromDate,
    toDate,
    dropdownCusip,
    dropdownSymbols,
    applySearchClick,
  } = commonTabProps
  const [actionedOnSaveFlag, setActionedOnSaveFlag] = useState(false)
  const [shortSaleTable, setShortSaleTable] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState<number>(0)
  const [actionedOnSaveResponse, setActionedOnSaveResponse] = useState('')
  const [selectedItems, setSelectedItems] = useState<IActionedOnItem[]>([])

  useEffect(() => {
    const summaryData = async () => {
      const timeStamp = await dispatch(getRegSHOTimeStamp(source))
      if (
        (actionedOnSaveFlag && actionedOnSaveResponse) ||
        (!actionedOnSaveFlag && applySearchClick)
      ) {
        const response = await dispatch(
          getRegSHOSummaryData(
            'search',
            source,
            fromDate,
            toDate,
            0,
            0,
            sorting,
            sortDirection,
            dropdownCusip,
            dropdownSymbols
          )
        )
      }
      setCommonTabProps &&
        setCommonTabProps({ ...commonTabProps, applySearchClick: false })
      setActionedOnSaveResponse('')
      setActionedOnSaveFlag(false)
    }
    ;(applySearchClick || actionedOnSaveResponse) && summaryData()
  }, [applySearchClick, actionedOnSaveResponse])
  const DownloadEXCEL = async () => {
    const response = await dispatch(
      getRegShoSummaryLevelExcelReportForP3(
        `RegShoSummary${source}`,
        source,
        fromDate,
        toDate,
        0,
        0,
        sorting,
        sortDirection,
        '',
        ''
      )
    )
  }

  const handleActionedOnChange = async (regSHOID: number, status: boolean) => {
    setSelectedItems((prevItems: any) => {
      const existingItem = prevItems.find(
        (item: IActionedOnItem) => item.id === regSHOID
      )

      if (existingItem) {
        return prevItems.map((item: IActionedOnItem) =>
          item.id === regSHOID ? { id: regSHOID, value: status } : item
        )
      } else {
        return [...prevItems, { id: regSHOID, value: status }]
      }
    })
  }

  const getShortSaleNetPosition = (id: number) => {
    setShortSaleTable(true)
    setSelectedRowId(id)
  }

  const handleActionedOnSaveClick = async () => {
    setActionedOnSaveFlag(true)
    setSelectedItems([])
    setActionedOnSaveResponse(
      await dispatch(saveActionedOnChanges(selectedItems))
    )
  }
  return (
    <>
      <ButtonWrapper
        onClick={async () => {
          DownloadEXCEL()
        }}
      >
        <SvgDownloadSmall
          fillColor={`${COLORS.UI.Primary50}`}
        ></SvgDownloadSmall>
        <WDStyledButtonText>
          <Label>EXCEL</Label>
        </WDStyledButtonText>
      </ButtonWrapper>
      <WDCard>
        <CardWrapper>
          <LabelHeader>
            <WDStyledCardHeading>
              <Label>{'204 Summary'}</Label>
            </WDStyledCardHeading>
          </LabelHeader>
          <LabelAmount>
            <WDStyledCardHeading>
              <Label
                onClick={() => {
                  setShowTimeStamp(true)
                }}
              >
                {DTCCTime?.ingestDateTime ? (
                  <Link style={{ color: 'white' }} to={''}>
                    DTCC Time:{' '}
                    {
                      new Date(DTCCTime.ingestDateTime)
                        ?.toISOString()
                        .split('T')[0]
                    }{' '}
                    {new Date(DTCCTime.ingestDateTime)?.toLocaleTimeString()}
                  </Link>
                ) : (
                  ''
                )}
              </Label>
            </WDStyledCardHeading>
          </LabelAmount>
        </CardWrapper>
      </WDCard>
      <StyledTableContainer>
        {loading && (
          <PopUpStyleLoader>
            <StyledSelectWrapper style={{ flexDirection: 'column' }}>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LottieWrapper style={{ left: '53.5%' }}>
                {' '}
                Loading...
              </LottieWrapper>
            </StyledSelectWrapper>
          </PopUpStyleLoader>
        )}

        {shortSaleTable && (
          <BetaShortSalePopUp
            setShortSaleTable={setShortSaleTable}
            selectedRowId={selectedRowId}
          />
        )}

        {showTimeStamp && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{
                padding: '15px 15px 0px 15px',
                fontWeight: '600',
                borderRadius: '8px 8px 0px 0px',
              }}
            >
              {`${source} Data`}
              <MarginLeftAutoSpace>
                <CancelButtonWrapper
                  onClick={() => {
                    setShowTimeStamp(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </CancelButtonWrapper>
              </MarginLeftAutoSpace>
              <Divider
                thickness={'1px'}
                horizontal={true}
                bgColor={Backgrounds.Gray110}
              />
            </StyledSelectWrapper>
            <div>
              <Table
                tableColumns={DtccTable}
                tableData={DTCCTime.dataForDate ? DTCCTime.dataForDate : []}
                onClick={() => {
                  //
                }}
              />
            </div>
          </PopUpStyleContent>
        )}
        <ToolSummaryTable
          columns={getToolSummaryColumns({
            handleActionedOnSaveClick,
            handleActionedOnChange,
            selectedItems,
          })}
          fromDate={fromDate}
          toDate={toDate}
          data={data.data ? data.data : []}
          totalRecords={TotalCount}
          dropdownCusip={dropdownCusip}
          dropdownSymbols={dropdownSymbols}
          commonTabProps={commonTabProps}
          setCommonTabProps={setCommonTabProps}
          setActiveTab={setActiveTab}
          handleActionedOnSaveClick={handleActionedOnSaveClick}
          filterData={filterData}
          setSummaryFilters={setSummaryFilters}
          setShortSaleTable={getShortSaleNetPosition}
        />
        {message.length > 0 && (
          <ToastWrapper>
            <Toast
              text={message}
              type={'success'}
              openStatusCallback={() => dispatch(setToastMessage(''))}
            />
          </ToastWrapper>
        )}
      </StyledTableContainer>
    </>
  )
}

export default ToolSummaryDetails
