/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  GoToPageWrapper,
  NextPriviousWrapper,
  PaginationContainer,
  RootContainerTable,
  PopUpStyleLoader,
  SpaceMaker,
  ColoumnPaddingTop,
  ForwardButtonWrapper,
  BackwardButtonWrapper,
  CheckboxStyle,
  ActionButtonsWrapper,
} from './styles'

import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useMountedLayoutEffect,
} from 'react-table'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LottieWrapper } from './styles'
import Lottie from 'lottie-react'
import Label from '../../../../components/label/Label'
import Loader from '../../../../assets/lottiefiles/loader.json'
import { COLORS } from '../../../../theme/Colors'
import SvgBackwardDoubleChevron from '../../../../components/svg/SvgBackwardDoubleChevron'
import SvgForwardDoubleChevron from '../../../../components/svg/SvgForwardDoubleChevron'

import {
  approveVoluntaryData,
  getVoluntaryEventsTrackingReportData,
  setSortDirection,
  setSorting,
  sortDirectionSelector,
  sortingSelector,
  updateRecord,
} from '../../../../store/asset-Servicing/Voluntary-Events-Tracking-Report'
import Button from '../../../../components/button/Button'

const IndeterminateCheckbox = ({ ...rest }) => {
  return (
    <>
      <CheckboxStyle type="checkbox" {...rest} />
    </>
  )
}
import { getAccessFlag } from '../../../../utils/globalFunctions'

const CreateTable = (props: any) => {
  const dispatch = useDispatch()
  const sortingCol = useSelector(sortingSelector)
  const sortingDir = useSelector(sortDirectionSelector)
  const [sortDirect, setSortDirect] = useState<any>('ASC')
  let [sortBy, setSortBy] = useState<any>('')
  const [popUpLoaderSort, setPopUpLoaderSort] = useState<boolean>(false)
  const [sortAction, setSortAction] = useState<boolean>(false)
  const [tempEvent, setTempEvent] = useState<string>('')
  const [pageNumberCall, setPageNumberCall] = useState<any>(1)
  const [pageStatus, setPageStatus] = useState<boolean>(false)
  const [timeoutId, setTimeoutId] = useState()
  const [selectedRows, setSelectedRows] = useState<any>()

  const INITIAL_SELECTED_ROW_IDS = {}

  const writeExecuteFlag = getAccessFlag(['AA-RWX', 'OM-RWX'])

  let data = props.data
  const totalRecords = props.totalRecords
  const columns = props.columns
  const regularDeadlineFrom = props.regularDeadlineFrom
  const regularDeadlineTo = props.regularDeadlineTo
  const caidOptionList = props.caidOptionList
  const cusip = props.cusip
  const eventTypeOptionsList = props.eventTypeOptionsList
  const earlyDeadlineFrom = props.earlyDeadlineFrom
  const earlyDeadlineTo = props.earlyDeadlineTo

  useEffect(() => {
    data = props.data
  }, [sortBy])

  let controlledPageCount: number
  controlledPageCount = Math.ceil(totalRecords / 20)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        pageIndex: 1,
        pageSize: 20,
        selectedRowIds: INITIAL_SELECTED_ROW_IDS, // Do not just use {}
      },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: '2%',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const { selectedRowIds } = state

  useMountedLayoutEffect(() => {
    const selectedIds = Object.keys(selectedRowIds)
    const selectedRowsData = selectedIds
      .map((x) => data[x])
      .filter(function (x) {
        return x != null
      })
    if (selectedRowsData?.length >= 1) setSelectedRows(selectedRowsData)
  }, [selectedRowIds])

  useEffect(() => {
    setPageNumberCall(1)
  }, [props.gotoFirstPage])

  const sorting = async (e: any) => {
    setPopUpLoaderSort(true)
    setTempEvent(e)

    sortBy = e.replace(/_Formatted/g, '')
    setSortBy([...sortBy])
    dispatch(setSorting(sortBy))

    if (sortDirect == 'ASC') {
      setSortDirect('DESC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(true)
    }

    if (sortDirect == 'DESC') {
      setSortDirect('ASC')
      dispatch(setSortDirection(sortDirect))
      setSortAction(false)
    }

    await dispatch(
      getVoluntaryEventsTrackingReportData(
        'sort',
        regularDeadlineFrom,
        regularDeadlineTo,
        caidOptionList,
        cusip,
        eventTypeOptionsList,
        earlyDeadlineFrom,
        earlyDeadlineTo,
        pageNumberCall - 1,
        20,
        sortBy,
        sortDirect
      )
    )
    setPopUpLoaderSort(false)
  }

  const pageCallFunction = async (pageIndex: any, rowRange: any) => {
    setPopUpLoaderSort(true)
    props.setUpdateRef(pageIndex)
    await dispatch(
      getVoluntaryEventsTrackingReportData(
        'search',
        regularDeadlineFrom,
        regularDeadlineTo,
        caidOptionList,
        cusip,
        eventTypeOptionsList,
        earlyDeadlineFrom,
        earlyDeadlineTo,
        pageIndex - 1,
        rowRange,
        sortingCol,
        sortingDir
      )
    )
    setPopUpLoaderSort(false)
  }

  useEffect(() => {
    if (pageStatus) {
      pageCallFunction(pageNumberCall, 20)
    }
  }, [pageNumberCall])

  const onChangeFunction = (pageValue: number) => {
    if (pageValue > 0 && pageValue <= pageCount) {
      const pageNumber = pageValue ? pageValue : 1
      setPageStatus(true)
      clearTimeout(timeoutId)
      const timeout: any = setTimeout(() => {
        gotoPage(pageNumber)
        setPageNumberCall(pageNumber)
      }, 1000)
      setTimeoutId(timeout)
    }
  }

  const callBulkApprove = async () => {
    const payload: any = []
    selectedRows.forEach((row: any) => {
      payload.push(row?.vetR_ID)
    })
    const response = await dispatch(approveVoluntaryData(payload))
    props.handleSearch()
  }

  const updateBulkRecords = async (payload: any) => {
    const response = await dispatch(updateRecord(payload))

    await dispatch(
      getVoluntaryEventsTrackingReportData(
        'search',
        regularDeadlineFrom,
        regularDeadlineTo,
        caidOptionList,
        cusip,
        eventTypeOptionsList,
        earlyDeadlineFrom,
        earlyDeadlineTo,
        pageNumberCall - 1,
        20,
        sortingCol,
        sortingDir
      )
    )
  }

  const callBulkUpdate = () => {
    const payload: any = []

    selectedRows.forEach((row: any, index: any) => {
      const uniqueId = row?.vetR_ID
      let updatedclientCutOff: any
      let updatedElections: any
      props.executionUserInputsRef.current.forEach((row: any) => {
        if (uniqueId == row?.vetR_ID) {
          updatedclientCutOff = row?.clientCutOff
          updatedElections = row?.elections
        }
      })
      const temp: any = {
        vetR_ID: row?.vetR_ID,
        cusip: row?.contra,
        createdBy: localStorage.getItem('AccountName'),
        clientCutOff: updatedclientCutOff,
        elections: updatedElections,
      }
      payload.push(temp)
    })

    updateBulkRecords(payload)
  }

  return (
    <>
      <ActionButtonsWrapper>
        <Button
          bgColor={'transparent'}
          color={COLORS.Background.Primarytext}
          padding={'4px 16px'}
          borderRadius={'4px'}
          style={{ whiteSpace: 'nowrap' }}
          type={'button'}
          disabled={!writeExecuteFlag}
          borderColor={COLORS.Background.Primarytext}
          onClick={() => {
            props.reOpenActions()
          }}
        >
          Reopen
        </Button>
        <Button
          bgColor={'transparent'}
          color={
            selectedRows?.length >= 1
              ? COLORS.Background.Primarytext
              : COLORS.Background.Neutral40
          }
          padding={'4px 16px'}
          borderRadius={'4px'}
          style={{ whiteSpace: 'nowrap' }}
          type={'button'}
          disabled={!writeExecuteFlag}
          borderColor={
            selectedRows?.length >= 1
              ? COLORS.Background.Primarytext
              : COLORS.Background.Neutral40
          }
          onClick={() => {
            callBulkUpdate()
          }}
        >
          Update
        </Button>
        <Button
          bgColor={'transparent'}
          color={
            selectedRows?.length >= 1
              ? COLORS.Background.Primarytext
              : COLORS.Background.Neutral40
          }
          padding={'4px 16px'}
          borderRadius={'4px'}
          style={{ whiteSpace: 'nowrap' }}
          type={'button'}
          disabled={!writeExecuteFlag}
          borderColor={
            selectedRows?.length >= 1
              ? COLORS.Background.Primarytext
              : COLORS.Background.Neutral40
          }
          onClick={() => {
            callBulkApprove()
          }}
        >
          Approve
        </Button>
      </ActionButtonsWrapper>
      <table {...getTableProps()}>
        {popUpLoaderSort && (
          <PopUpStyleLoader>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <SpaceMaker>
              <Label> Loading...</Label>
            </SpaceMaker>{' '}
          </PopUpStyleLoader>
        )}
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={key}>
              {headerGroup.headers.map((column: any, index) => (
                <th
                  key={index}
                  onClick={(e) => {
                    //trigger the react-table header onClick manually
                    if (
                      column.getHeaderProps(column.getSortByToggleProps())
                        .onClick
                    ) {
                      column
                        .getHeaderProps(column.getSortByToggleProps())
                        .onClick(e)

                      sorting(column.id)
                    }
                  }}
                >
                  {column.render('Header')}

                  <ColoumnPaddingTop>
                    {column.id == tempEvent ? (sortAction ? '🔼' : '🔽 ') : ''}
                  </ColoumnPaddingTop>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {data.length == 0 && (
        <>
          <Label fontWeight={700} margin="5% 0 0 50%">
            No Records Found
          </Label>
          <Label fontWeight={700} margin="0.5% 0 0 49%">
            No Exceptions Identified
          </Label>
        </>
      )}

      <PaginationContainer>
        <Label>
          Page{' '}
          <strong>
            {pageNumberCall} of {pageOptions.length}
          </strong>{' '}
        </Label>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <GoToPageWrapper>
          <Label>Go to page:</Label>
          <input
            type="number"
            min="1"
            max={pageCount}
            defaultValue={pageNumberCall}
            onChange={(e) => {
              onChangeFunction(Number(e.target.value))
            }}
            style={{ width: '50px' }}
          />
        </GoToPageWrapper>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <NextPriviousWrapper>
          <BackwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(1)
              setPageNumberCall(1)
            }}
          >
            {pageNumberCall !== 1 ? (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgBackwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </BackwardButtonWrapper>
          <button
            onClick={() => {
              setPageStatus(true)
              previousPage()
              setPageNumberCall(pageNumberCall - 1)
            }}
            disabled={pageNumberCall === 1}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              setPageStatus(true)
              nextPage()
              setPageNumberCall(pageNumberCall + 1)
            }}
            disabled={pageNumberCall === pageCount}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Next
          </button>
          <ForwardButtonWrapper
            onClick={() => {
              setPageStatus(true)
              gotoPage(pageCount)
              setPageNumberCall(pageCount)
            }}
          >
            {pageNumberCall !== pageCount ? (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Icons.NeutralIcon}`}
              />
            ) : (
              <SvgForwardDoubleChevron
                fillColor={`${COLORS.Background.Neutral40}`}
              />
            )}
          </ForwardButtonWrapper>
        </NextPriviousWrapper>
      </PaginationContainer>
    </>
  )
}

const SummaryTable = (props: any) => {
  return (
    <RootContainerTable>
      <CreateTable
        columns={props.columns}
        data={props.data}
        regularDeadlineFrom={props.regularDeadlineFrom}
        regularDeadlineTo={props.regularDeadlineTo}
        caidOptionList={props.caidOptionList}
        cusip={props.cusip}
        eventTypeOptionsList={props.eventTypeOptionsList}
        earlyDeadlineFrom={props.earlyDeadlineFrom}
        earlyDeadlineTo={props.earlyDeadlineTo}
        totalRecords={props.totalRecords}
        setUpdateRef={props.setUpdateRef}
        gotoFirstPage={props.gotoFirstPage}
        handleSearch={props.handleSearch}
        executionUserInputsRef={props.executionUserInputsRef}
        reOpenActions={props.reOpenActions}
      />
    </RootContainerTable>
  )
}

export default SummaryTable
