/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  voluntaryEventsReportData: []
  totalCount: number
  isLoading: boolean
  action: string
  comments: any
  updateApproveAuditTrail: any
  corporateActionAuditTrail: any
  sorting: any
  sortDirection: any
  httpErrorMessage: string
  successMessage: string
}

const initialState: State = {
  voluntaryEventsReportData: [],
  totalCount: 0,
  isLoading: false,
  action: '',
  comments: [],
  updateApproveAuditTrail: [],
  corporateActionAuditTrail: [],
  sorting: 'Contra',
  sortDirection: 'ASC',
  httpErrorMessage: '',
  successMessage: '',
}

const { actions, reducer }: Slice = createSlice({
  name: 'VoluntaryEventsTrackingReport',
  initialState,
  reducers: {
    SetVoluntaryEventsReportData: (
      state: State,
      action: PayloadAction<any>
    ) => {
      state.voluntaryEventsReportData = action.payload.data
    },
    setTotalCount: (state: State, action: PayloadAction<any>) => {
      state.totalCount = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setComments: (state: State, action: PayloadAction<any>) => {
      state.comments = action.payload.data
    },
    setUpdateApproveAuditTrail: (state: State, action: PayloadAction<any>) => {
      state.updateApproveAuditTrail = action.payload.data
    },
    setCorporateActionAuditTrail: (
      state: State,
      action: PayloadAction<any>
    ) => {
      state.corporateActionAuditTrail = action.payload.data
    },
    setSorting: (state: State, action: PayloadAction<any>) => {
      state.sorting = action.payload
    },
    setSortDirection: (state: State, action: PayloadAction<any>) => {
      state.sortDirection = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  SetVoluntaryEventsReportData,
  setTotalCount,
  setIsLoading,
  setAction,
  setComments,
  setUpdateApproveAuditTrail,
  setCorporateActionAuditTrail,
  setSorting,
  setSortDirection,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getVoluntaryEventsTrackingReportData: any =
  (
    action: string,
    regularFromDate: string,
    regularToDate: string,
    caid: string,
    cusip: string,
    eventType: string,
    earlyFromDate: string,
    earlyToDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/GetVoluntrayEventsTrackingReport',
        {
          regularFromDate,
          regularToDate,
          caid,
          cusip,
          eventType,
          earlyFromDate,
          earlyToDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setAction(action))
        dispatch(SetVoluntaryEventsReportData(data))
        dispatch(setTotalCount(data.totalCount))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const getVoluntaryEventsTrackingReopenReportData: any =
  (
    action: string,
    regularFromDate: string,
    regularToDate: string,
    caid: string,
    cusip: string,
    eventType: string,
    earlyFromDate: string,
    earlyToDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/GetVoluntrayEventsTrackingReportForReopen',
        {
          regularFromDate,
          regularToDate,
          caid,
          cusip,
          eventType,
          earlyFromDate,
          earlyToDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setAction(action))
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const putUserComment: any =
  (vetR_ID: number, cusip: string, comment: string, createdBy: string) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/AddVoluntrayEventComment',
        {
          vetR_ID,
          cusip,
          comment,
          createdBy,
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('Added Successfully'))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const getComments: any = (id: number) => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.post(
      'opsstaticdataapi/AssetServicing/GetVoluntrayEventComments',
      {
        id,
      }
    )

    if (status === 200) {
      dispatch(setIsLoading(false))
      dispatch(
        setComments({
          data: data,
        })
      )
      return status
    }
  } catch (e: any) {
    dispatch(setIsLoading(false))
    handleHttpError(dispatch, e, setHttpErrorMessage)
    dispatch(
      setComments({
        data: [],
      })
    )
  }
}

export const getUpdateApproveAuditTrail: any =
  (id: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsstaticdataapi/AssetServicing/GetVoluntrayEventApproveOrUpdateAudit?VETR_ID=${id}`
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(
          setUpdateApproveAuditTrail({
            data: data,
          })
        )
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        setUpdateApproveAuditTrail({
          data: [],
        })
      )
    }
  }

export const getCorporateActionAuditTrail: any =
  (caid: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsstaticdataapi/AssetServicing/GetVoluntrayCorporateAuditHistory?caid=${caid}`
      )

      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(
          setCorporateActionAuditTrail({
            data: data,
          })
        )
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(
        setCorporateActionAuditTrail({
          data: [],
        })
      )
    }
  }

export const approveVoluntaryData: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/ApproveVoluntaryoData',
        payload
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('Approved Successfully'))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const updateRecord: any =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsstaticdataapi/AssetServicing/UpdateVoluntaryData`,
        payload
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('Updated Successfully'))
        return data
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const getVoluntaryEventExcel: any =
  (
    fileName: string,
    regularFromDate: string,
    regularToDate: string,
    caid: string,
    cusip: string,
    eventType: string,
    earlyFromDate: string,
    earlyToDate: string,
    pageNumber: number,
    pageSize: number,
    sortBy: string,
    sortDirection: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/GetVoluntartEventExcelExport',
        {
          regularFromDate,
          regularToDate,
          caid,
          cusip,
          eventType,
          earlyFromDate,
          earlyToDate,
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
        },
        { responseType: 'blob' }
      )
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('File Downloaded Successfully'))
        return status
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const voluntaryEventsReportDataSelector = (store: StoreState) =>
  store.assetService.VoluntaryEventsTrackingReport.voluntaryEventsReportData
export const actionSelector = (store: StoreState) =>
  store.assetService.VoluntaryEventsTrackingReport.action
export const isLoadingSelector = (store: StoreState) =>
  store.assetService.VoluntaryEventsTrackingReport.isLoading
export const commentSelector = (store: StoreState) =>
  store.assetService.VoluntaryEventsTrackingReport.comments
export const auditTrailSelector = (store: StoreState) =>
  store.assetService.VoluntaryEventsTrackingReport.updateApproveAuditTrail
export const corporateActionAuditTrailSelector = (store: StoreState) =>
  store.assetService.VoluntaryEventsTrackingReport.corporateActionAuditTrail
export const sortingSelector = (store: StoreState) =>
  store.assetService.VoluntaryEventsTrackingReport.sorting
export const sortDirectionSelector = (store: StoreState) =>
  store.assetService.VoluntaryEventsTrackingReport.sortDirection
export const TotalCountSelector = (store: StoreState) =>
  store.assetService.VoluntaryEventsTrackingReport.totalCount
export const HttpErrorMessageSelector = (store: StoreState) =>
  store.assetService.VoluntaryEventsTrackingReport.httpErrorMessage
export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.assetService.VoluntaryEventsTrackingReport.successMessage
export default reducer
