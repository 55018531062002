export const shortSalesDetailsColumns = () => {
  return [
    {
      Header: 'Account Number',
      accessor: 'accountNumber',
    },
    {
      Header: 'Account Type',
      accessor: 'accountType',
    },
    {
      Header: 'Account Name 1',
      accessor: 'accountName1',
    },
    {
      Header: 'Account Name 2',
      accessor: 'accountName2',
    },
    {
      Header: 'Account Name 3',
      accessor: 'accountName3',
    },
    {
      Header: 'Office Branch',
      accessor: 'officeBranch',
    },
    {
      Header: 'Rep Code',
      accessor: 'repCode',
    },
    {
      Header: 'Rep Name',
      accessor: 'repName',
    },
    {
      Header: 'Trade Date',
      accessor: 'tradeDate',
    },
    {
      Header: 'Settle Date',
      accessor: 'settleDate',
    },
    {
      Header: 'As Of Date',
      accessor: 'asOfDate',
    },
    {
      Header: 'Order Date',
      accessor: 'orderDate',
    },
    {
      Header: 'Execution Time',
      accessor: 'executionTime',
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      Header: 'CUSIP',
      accessor: 'cusip',
    },
    {
      Header: 'Security Number',
      accessor: 'securityNumber',
    },
    {
      Header: 'Security Description',
      accessor: 'securityDescription',
    },
    {
      Header: 'Trade Quantity',
      accessor: 'tradeQuantity',
    },
    {
      Header: 'Trade Price',
      accessor: 'tradePrice',
    },
    {
      Header: 'Short Sale Code',
      accessor: 'shortSaleCode',
    },
    {
      Header: 'Security Type',
      accessor: 'securityType',
    },
    {
      Header: 'Price Entered',
      accessor: 'priceEntered',
    },
    {
      Header: 'Look Alike Commission',
      accessor: 'lookalikeCommission',
    },
    {
      Header: 'Solicit Code',
      accessor: 'solicitCode',
    },
    {
      Header: 'Time In Fan',
      accessor: 'timeInFan',
    },
    {
      Header: 'All Or Nothing',
      accessor: 'allOrNothing',
    },
    {
      Header: 'Insitution',
      accessor: 'institution',
    },
    {
      Header: 'Trade Captured',
      accessor: 'tradeCaptured',
    },
    {
      Header: 'Exchange Bank',
      accessor: 'exchangeBank',
    },
    {
      Header: 'Order Number',
      accessor: 'orderNumber',
    },
    {
      Header: 'Branch Sequence',
      accessor: 'branchSequence',
    },
    {
      Header: 'Product Code',
      accessor: 'productCode',
    },
    {
      Header: 'Order Enter Id',
      accessor: 'orderEnterId',
    },
    {
      Header: 'Order Enter Time',
      accessor: 'orderEnterTime',
    },
    {
      Header: 'Order Accept Time',
      accessor: 'orderAcceptTime',
    },
    {
      Header: 'Buy Sell Code',
      accessor: 'buySell',
    },
    {
      Header: 'Short Sale',
      accessor: 'shortSale',
    },
    {
      Header: 'Action Code',
      accessor: 'actionCode',
    },
    {
      Header: 'Currency Code',
      accessor: 'currencyCode',
    },
    {
      Header: 'Net Amount',
      accessor: 'netAmount',
    },
    {
      Header: 'Principal Amount',
      accessor: 'principalAmount',
    },
    {
      Header: 'Commission Amount',
      accessor: 'commissionAmount',
    },
    {
      Header: 'Gross Commission',
      accessor: 'grossCommission',
    },
    {
      Header: 'Prime Broker',
      accessor: 'primeBroker',
    },
    {
      Header: 'Postage Amount',
      accessor: 'postageAmount',
    },
    {
      Header: 'Cancelled',
      accessor: 'cancelled',
    },
    {
      Header: 'Cancelled Reason',
      accessor: 'cancelledReason',
    },
    {
      Header: 'Rebilled Trade',
      accessor: 'rebilledTrade',
    },
    {
      Header: 'Offset Account Number',
      accessor: 'offsetAccountNumber',
    },
    {
      Header: 'Contrabroker Number',
      accessor: 'contraBrokerNumber',
    },
    {
      Header: 'Do Not Reduce',
      accessor: 'doNotReduce',
    },
    {
      Header: 'Commmision Code',
      accessor: 'commissionCode',
    },
    {
      Header: 'Stop Order',
      accessor: 'stopOrder',
    },
    {
      Header: 'Sec Fee Amount',
      accessor: 'secFeeAmount',
    },
    {
      Header: 'Market Or Limit',
      accessor: 'marketOrLimit',
    },
    {
      Header: 'Nasdaq Broker Symbol',
      accessor: 'nasdaqBrokerSymbol',
    },
    {
      Header: 'Contra Dealer Number',
      accessor: 'contraDealerNumber',
    },
    {
      Header: 'State Code',
      accessor: 'stateCode',
    },
    {
      Header: 'Nasd Class',
      accessor: 'nasdClass',
    },
    {
      Header: 'Trade Indication',
      accessor: 'tradeIndication',
    },
  ]
}
