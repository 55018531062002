import Checkbox from '../../../../components/checkbox/Checkbox'
import FreeFormInputFilter, {
  ExclusiveCheckboxFilter,
  MultiSelectFilter,
} from '../../CommonComponents'

interface IGetToolSummaryColumnsProps {
  handleActionedOnChange: (regSHOId: number, status: boolean) => void
  handleActionedOnSaveClick: () => void
  selectedItems: { id: number; value: boolean }[]
}

export const getToolSummaryColumns = ({
  handleActionedOnSaveClick,
  handleActionedOnChange,
  selectedItems,
}: IGetToolSummaryColumnsProps) => {
  const actionedOnCheckedStatus = (row: any) => {
    const actionedOn = selectedItems.filter(
      (item) => item.id == row.values.regSHO_Id
    )
    const returnBool =
      actionedOn && actionedOn.length > 0
        ? actionedOn[0].value
        : row.values.actionedOn
    return returnBool
  }
  return [
    {
      Header: 'Id',
      accessor: 'regSHO_Id',
      show: false,
    },
    {
      Header: '-',
      columns: [
        {
          Header: 'CUSIP',
          accessor: 'cusiP_Number',
          Cell: 'cusipNumber',
          Filter: MultiSelectFilter,
          filter: 'textArrayFilter',
        },
        {
          Header: 'Symbol',
          accessor: 'symbol',
          Filter: MultiSelectFilter,
          filter: 'textArrayFilter',
        },
        {
          Header: ({ column }: any) => <div>Actioned on?</div>,
          accessor: 'actionedOn',
          Filter: ExclusiveCheckboxFilter,
          filter: 'staticOptionsFilter',
          Cell: ({ row, column }: any) => {
            return (
              <Checkbox
                checked={actionedOnCheckedStatus(row)}
                onChange={(event: any) => {
                  handleActionedOnChange(
                    row.values.regSHO_Id,
                    event.target.checked
                  )
                }}
              />
            )
          },
        },
        {
          Header: 'Type',
          accessor: 'type',
          Filter: MultiSelectFilter,
          filter: 'textArrayFilter',
        },
        {
          Header: 'T+N',
          accessor: 'tn',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
        {
          Header: 'BorrowDesc',
          accessor: 'borrowDesc',
          Filter: MultiSelectFilter,
          filter: 'textArrayFilter',
        },
        {
          Header: 'ThresholdInd',
          accessor: 'thresholdIndex',
          Filter: MultiSelectFilter,
          filter: 'textArrayFilter',
        },
        {
          Header: 'Short Sale Net Position',
          accessor: 'shortSaleTradeNet',
          Cell: 'shortSaleTradeNet',
          Filter: MultiSelectFilter,
          filter: 'textArrayFilter',
        },
        {
          Header: 'FailQty',
          accessor: 'failQty',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
      ],
    },
    {
      Header: 'Current Day Activity',
      columns: [
        {
          Header: 'New',
          accessor: 'currentNew',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
        {
          Header: 'Made',
          accessor: 'currentMade',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
        {
          Header: 'Early sales Release Indicator (in Quantity)',
          accessor: 'earlySalesReleaseInd',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
        {
          Header: 'MISC',
          accessor: 'currentMisc',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
      ],
    },
    {
      Header: '-',
      columns: [
        {
          Header: 'Net Item to work today',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
          accessor: 'netItemToWorkToday',
        },
        {
          Header: 'Todays 204 Need',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
          accessor: 'today204Need',
        },
      ],
    },
    {
      Header: 'Allocations',
      columns: [
        {
          Header: 'Correspondent Allocation',
          accessor: 'correspondentAllocation',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
        {
          Header: 'Fail Allocation (non-customer)',
          accessor: 'failAllocation',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
        {
          Header: 'Customer Allocation',
          accessor: 'customerAllocation',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
        {
          Header: 'Stock Loan Allocation',
          accessor: 'stockLoanAllocation',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
        {
          Header: 'Others',
          accessor: 'otherAllocation',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
      ],
    },
    {
      Header: '-',
      columns: [
        {
          Header: 'Net Open 204 Item',
          accessor: 'netOpen204Item',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
        {
          Header: 'Todays BuyIn Date',
          accessor: 'nextBuyinDate',
          Filter: MultiSelectFilter,
          filter: 'textArrayFilter',
        },
        {
          Header: 'Buyin Ticket #',
          accessor: 'buyinTIcketNo',
          Filter: MultiSelectFilter,
          filter: 'textArrayFilter',
        },
        {
          Header: 'Todays Buy-In Execution Quantity',
          accessor: 'buyInAmount',
          Filter: FreeFormInputFilter,
          filter: 'numberFilter',
        },
      ],
    },
    {
      Header: '-',
      columns: [
        {
          Header: 'Action Date',
          accessor: 'reportDate',
          Filter: MultiSelectFilter,
          filter: 'textArrayFilter',
        },
        {
          Header: 'Fail Date',
          accessor: 'failDate',
          Filter: MultiSelectFilter,
          filter: 'textArrayFilter',
        },
      ],
    },
  ]
}

export const DtccTable = [
  {
    Header: 'File Name',
    accessor: 'dtccFunction',
  },
  {
    Header: 'Time of update in database',
    accessor: 'ingestDatetime',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
]
