/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Textarea } from '../../../components/textArea/Textarea'
import {
  WDStyledButtonText,
  WDStyledLabel,
} from '../../../components/ui/WDTypography'
import {
  BoldTableData,
  ButtonWrapper,
  CancelButtonWrapper,
  CommentBody,
  CommentHeader1,
  CommentHeaderSub1,
  CommentHeaderSub2,
  CommentLabel,
  CommentTableContainer,
  DataTable,
  EmailButtonWrapper,
  FirstBottomHalf,
  FirstHalf,
  HeadingExcelWrapper,
  MainDataContainer,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  RootContainer,
  RootContainerTable,
  SecondHalf,
  SecurityDescriptionWrapper,
  TableData,
  TableRowContainer,
  ViewSubmitButtonWrapper,
} from './styles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  LoadingSelector,
  ToastMessageSummarySelector,
  addRegShoListComments,
  emailUploadL1,
  getRegSHOSummaryData,
  getRegSho204ExcelReportForP3,
  getRegShoListComments,
  latestComment,
  setToastMessage,
  sortDirectionSelector,
  sortingSelector,
  updateRegShoListComments,
} from '../../../store/reg-SHO-Tool/Reg-SHO-Summary'
import {
  LottieWrapper,
  PopUpStyleLoader,
  StyledSelectWrapper,
  ToastWrapper,
} from '../styles'
import Loader from '../../../assets/lottiefiles/loader.json'
import Lottie from 'lottie-react'
import { Toast } from '../../../components/toast/Toast'
import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Divider from '../../../components/divider/Divider'
import Table from '../../../components/table/Table'
import { Backgrounds, Colors } from '../../../shared/styles'
import NextBuyinDate from './NextBuyinDate'
import Label from '../../../components/label/Label'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { TabListProps } from '../../../components/tab/TabListModels'
import { getCusipL1Columns } from './CusipL1Columns'
import CusipL1SummaryTable from './table/Table'
import { getAccessFlag } from '../../../utils/globalFunctions'
import FileUpload from '../BuyInAllocationAndExecution/FileUploadModal'
import { EmailList } from '../BuyInAllocationAndExecution/EmailList'

const RegSHOCusipSummary = ({
  commonTabProps,
  setCommonTabProps,
  setActiveTab,
}: TabListProps) => {
  const dispatch = useDispatch()
  const sorting = useSelector(sortingSelector)
  const sortDirection = useSelector(sortDirectionSelector)
  const loading = useSelector(LoadingSelector)
  const message = useSelector(ToastMessageSummarySelector)
  const [data, setData] = useState<any>()
  const [dateValidation, setDateValidation] = useState<any>()
  const [openBalanceToWork, setOpenBalanceToWork] = useState<number>(0)
  const [totalAllocations, setTotalAllocations] = useState<number>(0)
  const [viewAllComments, setViewAllComments] = useState<boolean>(false)
  const [openUpdateCommentField, setOpenUpdateCommentField] =
    useState<boolean>(false)
  const [openNewCommentField, setOpenNewCommentField] = useState<boolean>(false)
  const [accessFlag, setAccessFlag] = useState<boolean>(false)
  const [userComment, setUserComment] = useState<any>([])
  const [inputComment, setInputComment] = useState('')
  const [mailList, setMailList] = useState(false)
  const [fileData, setFileData] = useState<File>()
  const [mailPOPUP, setMailPOPUP] = useState<boolean>(false)
  const [openAuditTrail, setOpenAuditTrail] = useState<boolean>(false)
  const [latestCusipComment, setLatestCusipComment] = useState({
    comment: '',
    l1_Comment_Id: 0,
  })
  const [id, setId] = useState<number>(0)
  const {
    regSHOId,
    source,
    fromDate,
    toDate,
    dropdownCusip,
    dropdownSymbols,
    applySearchClick,
    selectedSummaryTableCUSIP,
    allocationType,
    failDate,
    approveFlag,
    tn,
  } = commonTabProps
  const currentDate = new Date()

  const LatestComnt = async (Cusip: string) => {
    const latestCommentResponse = await dispatch(
      latestComment(source, Cusip, failDate)
    )
    setLatestCusipComment({
      l1_Comment_Id: latestCommentResponse?.l1_Comment_Id,
      comment: latestCommentResponse?.comment,
    })
    setId(latestCommentResponse?.l1_Comment_Id)
  }

  useEffect(() => {
    const selectedCusipSummary = async () => {
      const response = await dispatch(
        getRegSHOSummaryData(
          'search',
          source,
          fromDate,
          toDate,
          0,
          20,
          sorting,
          sortDirection,
          selectedSummaryTableCUSIP,
          '',
          regSHOId
        )
      )

      if (response) {
        await LatestComnt(selectedSummaryTableCUSIP)
        await setData(response)
        await setDateValidation(
          data?.data[0]?.reportDate >= currentDate?.toISOString()?.split('T')[0]
        )
      }
    }
    selectedCusipSummary()
    setAccessFlag(getAccessFlag(['SS-RWX,OM-RWX']))
  }, [])

  useEffect(() => {
    if (data?.data[0]) {
      const correspondentAllocation =
        data?.data[0]?.correspondentAllocation?.replaceAll(',', '')
      const failAllocation = data?.data[0]?.failAllocation?.replaceAll(',', '')
      const customerAllocation = data?.data[0]?.customerAllocation?.replaceAll(
        ',',
        ''
      )
      const stockAllocation = data?.data[0]?.stockLoanAllocation?.replaceAll(
        ',',
        ''
      )
      const otherAllocation = data?.data[0]?.otherAllocation?.replaceAll(
        ',',
        ''
      )
      const netItemToWorkToday = data?.data[0]?.netItemToWorkToday?.replaceAll(
        ',',
        ''
      )
      const localSum =
        parseInt(correspondentAllocation, 10) +
        parseInt(failAllocation, 10) +
        parseInt(customerAllocation, 10) +
        parseInt(stockAllocation, 10) +
        parseInt(otherAllocation, 10)

      const workBalanceSum = parseInt(netItemToWorkToday, 10) + localSum
      setTotalAllocations(localSum)
      setOpenBalanceToWork(workBalanceSum)
    }
  }, [data])

  const handleCommentSubmit = async () => {
    const response = await dispatch(
      addRegShoListComments(
        source,
        inputComment,
        regSHOId,
        selectedSummaryTableCUSIP,
        failDate,
        tn
      )
    )

    if (response == 200) {
      await LatestComnt(selectedSummaryTableCUSIP)
      setOpenNewCommentField(false)
      setLatestCusipComment({ ...latestCusipComment, comment: inputComment })
    }
    setOpenNewCommentField(false)
  }

  const UpdateCommentSubmit = async (id: number, comment: string) => {
    const response = await dispatch(
      updateRegShoListComments(source, id, comment)
    )
    if (response) {
      setOpenUpdateCommentField(false)
      setLatestCusipComment({
        ...latestCusipComment,
        comment: comment,
        l1_Comment_Id: id,
      })
    } else {
      setOpenUpdateCommentField(false)
    }
  }

  const handleViewComment = async () => {
    const response = await dispatch(
      getRegShoListComments(source, selectedSummaryTableCUSIP, failDate)
    )
    setUserComment(response)
    await setViewAllComments(true)
  }

  const DownloadEXCEL = async () => {
    const response = await dispatch(
      getRegSho204ExcelReportForP3(
        `L1CusipLevelSummary${source}_${selectedSummaryTableCUSIP}`,
        source,
        fromDate,
        toDate,
        0,
        0,
        sorting,
        sortDirection,
        selectedSummaryTableCUSIP,
        '',
        regSHOId,
        failDate
      )
    )
  }

  const EmailUploadL1 = async (fileName: string) => {
    const response = await dispatch(
      emailUploadL1({ fileName, regSHO_Id: regSHOId, fileData })
    )
    response && setMailPOPUP(false)
  }
  return (
    <RootContainer>
      <HeadingExcelWrapper>
        <ButtonWrapper
          onClick={async () => {
            DownloadEXCEL()
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label>EXCEL</Label>
          </WDStyledButtonText>
        </ButtonWrapper>
      </HeadingExcelWrapper>
      <SecurityDescriptionWrapper>
        <DataTable>
          <tbody>
            <TableRowContainer>
              <BoldTableData>Security Description:</BoldTableData>
              <TableData>{data?.data[0]?.symbol}</TableData>
              <TableData>{data?.data[0]?.cusiP_Number}</TableData>
              <TableData>{data?.data[0]?.securityDescription}</TableData>
              <TableData>{data?.data[0]?.borrowDesc}</TableData>
              <TableData>{data?.data[0]?.thresholdIndex}</TableData>
            </TableRowContainer>
          </tbody>
        </DataTable>
      </SecurityDescriptionWrapper>
      <MainDataContainer>
        <FirstHalf>
          <DataTable>
            <tbody>
              <TableRowContainer>
                <BoldTableData>FailQty</BoldTableData>
                <TableData>
                  {data?.data[0]?.failQty
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </TableData>
              </TableRowContainer>
              <TableRowContainer>
                <BoldTableData>New</BoldTableData>
                <TableData>{data?.data[0]?.currentNew}</TableData>
              </TableRowContainer>
              <TableRowContainer>
                <BoldTableData>Made</BoldTableData>
                <TableData>{data?.data[0]?.currentMade}</TableData>
              </TableRowContainer>
              <TableRowContainer>
                <BoldTableData>
                  Early sales Release Indicator (in Quantity)
                </BoldTableData>
                <TableData>{}</TableData>
              </TableRowContainer>
              <TableRowContainer>
                <BoldTableData>MISC</BoldTableData>
                <TableData>{}</TableData>
              </TableRowContainer>
              <TableRowContainer>
                <BoldTableData>Net</BoldTableData>
                <TableData>{data?.data[0]?.netItemToWorkToday}</TableData>
              </TableRowContainer>
            </tbody>
          </DataTable>
          <FirstBottomHalf>
            <DataTable>
              <tbody>
                <tr>
                  <BoldTableData>Allocations:</BoldTableData>
                  <TableData>
                    {totalAllocations
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </TableData>
                </tr>
              </tbody>
            </DataTable>
          </FirstBottomHalf>
          <FirstBottomHalf>
            <DataTable>
              <tbody>
                <tr>
                  <BoldTableData>Open Balance to Work</BoldTableData>
                  <TableData>{}</TableData>
                </tr>
              </tbody>
            </DataTable>
          </FirstBottomHalf>
        </FirstHalf>
        <SecondHalf>
          <RootContainerTable>
            <CusipL1SummaryTable
              tableColumns={getCusipL1Columns(setCommonTabProps, setActiveTab)}
              tableData={data?.data[0] ? [data?.data[0]] : []}
              // onClick={() => {
              //   //
              // }}
            />
          </RootContainerTable>
          <NextBuyinDate
            commonTabProps={commonTabProps}
            accessFlag={
              approveFlag ||
              !(
                data?.data[0]?.reportDate >=
                currentDate?.toISOString()?.split('T')[0]
              )
            }
            source={source}
            regSHOId={regSHOId}
            reportDate={data?.data[0]?.reportDate}
            data={data}
            selectedSummaryTableCUSIP={selectedSummaryTableCUSIP}
          ></NextBuyinDate>
          <CommentHeader1>
            <CommentHeaderSub1>
              <CommentLabel>
                <EmailButtonWrapper>
                  <Button
                    disabled={
                      approveFlag ||
                      !(
                        data?.data[0]?.reportDate >=
                        currentDate?.toISOString()?.split('T')[0]
                      )
                    }
                    bgColor={'transparent'}
                    color={COLORS.Background.Primarytext}
                    padding={'4px 16px'}
                    borderRadius={'4px'}
                    type={''}
                    margin={''}
                    onClick={() => {
                      setMailPOPUP(true)
                    }}
                  >
                    Upload Email
                  </Button>
                  <Button
                    padding="2px"
                    margin="0px 6px"
                    bgColor={'transparent'}
                    onClick={() => {
                      setMailList(true)
                    }}
                    type={''}
                    color={''}
                  >
                    <SvgDownloadSmall
                      fillColor={Colors.Primary80}
                    ></SvgDownloadSmall>
                  </Button>
                </EmailButtonWrapper>
                <WDStyledLabel>Comments:</WDStyledLabel>
              </CommentLabel>
              <CommentBody>
                <Label>ViewAll Comments:</Label>
                <Button
                  bgColor={'transparent'}
                  color={COLORS.Background.Primarytext}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={''}
                  margin={'10px'}
                  onClick={() => {
                    handleViewComment()
                  }}
                >
                  View Comment
                </Button>
              </CommentBody>
              <CommentBody>
                <Label>Addnew Comments:</Label>
                <Button
                  disabled={
                    (approveFlag && accessFlag) ||
                    !(
                      data?.data[0]?.reportDate >=
                      currentDate?.toISOString()?.split('T')[0]
                    )
                  }
                  bgColor={'transparent'}
                  color={COLORS.Background.Primarytext}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={''}
                  margin={'10px'}
                  onClick={() => {
                    setOpenNewCommentField(true)
                    setInputComment('')
                  }}
                >
                  Add Comment
                </Button>
              </CommentBody>
              <CommentBody>
                Latest Comment:{' '}
                <Label
                  color="blue"
                  cursor="pointer"
                  margin="2px"
                  onClick={() => {
                    !accessFlag && setOpenUpdateCommentField(true)
                    !accessFlag && setInputComment(latestCusipComment.comment)
                  }}
                >
                  {latestCusipComment.comment
                    ? latestCusipComment.comment?.length > 25
                      ? `${latestCusipComment.comment?.slice(0, 26)}...`
                      : latestCusipComment.comment
                    : '-'}
                </Label>
              </CommentBody>
            </CommentHeaderSub1>
            <CommentHeaderSub2>
              {(openUpdateCommentField || openNewCommentField) && (
                <>
                  <Textarea
                    onChange={(e: any) => {
                      setInputComment(e.target.value)
                    }}
                    defaultValue={
                      openUpdateCommentField ? latestCusipComment.comment : ''
                    }
                    placeholder={'Enter Comment'}
                  ></Textarea>

                  <ViewSubmitButtonWrapper style={{ marginLeft: 'auto' }}>
                    <Button
                      hoverBgColor={COLORS.Background.Primary70}
                      bgColor={COLORS.Background.Primarytext}
                      color={COLORS.Background.Primary}
                      padding={'4px 16px'}
                      borderRadius={'4px'}
                      hover={true}
                      type={''}
                      margin={'10px'}
                      activeBgColor={COLORS.Background.Primaryactive}
                      borderColor={'transparent'}
                      onClick={() => {
                        setOpenUpdateCommentField(false)
                        setOpenNewCommentField(false)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      hoverBgColor={COLORS.Background.Primary70}
                      bgColor={COLORS.Background.Primarytext}
                      color={COLORS.Background.Primary}
                      padding={'4px 16px'}
                      borderRadius={'4px'}
                      hover={true}
                      type={''}
                      margin={'10px'}
                      activeBgColor={COLORS.Background.Primaryactive}
                      borderColor={'transparent'}
                      onClick={() => {
                        openUpdateCommentField
                          ? UpdateCommentSubmit(id, inputComment)
                          : handleCommentSubmit()
                      }}
                    >
                      {openUpdateCommentField ? 'Update' : 'Add'}
                    </Button>
                  </ViewSubmitButtonWrapper>
                </>
              )}
            </CommentHeaderSub2>
          </CommentHeader1>
        </SecondHalf>
      </MainDataContainer>
      {mailList && (
        <EmailList
          dataLevel={'L1'}
          allocationId={null}
          commonTabProps={commonTabProps}
          ClosePopUp={() => {
            setMailList(false)
          }}
          disableDeleteClickFlag={
            !(
              data?.data[0]?.reportDate >=
              currentDate?.toISOString()?.split('T')[0]
            )
          }
        />
      )}
      {viewAllComments && (
        <PopUpStyleContent
          style={{ height: '80%', overFlow: 'auto', width: '90%' }}
        >
          <StyledSelectWrapper
            style={{
              padding: '15px 15px 0px 15px',
              fontWeight: '600',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <>All Comments</>
            <MarginLeftAutoSpace>
              <CancelButtonWrapper
                onClick={() => {
                  setViewAllComments(false)
                }}
              >
                <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
              </CancelButtonWrapper>
            </MarginLeftAutoSpace>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
          </StyledSelectWrapper>
          <CommentTableContainer>
            <Table
              tableColumns={[
                {
                  Header: 'Comment',
                  accessor: 'comment',
                },
                {
                  Header: 'Created by',
                  accessor: 'createdBy',
                },
                {
                  Header: 'Create on(EST)',
                  accessor: 'createdOn',
                },
              ]}
              tableData={userComment ? userComment : []}
              onClick={() => {
                //
              }}
            />
            {userComment.length == 0 && <h3>No Comments</h3>}
          </CommentTableContainer>
        </PopUpStyleContent>
      )}
      {mailPOPUP && (
        <FileUpload
          ClosePopUp={() => {
            setMailPOPUP(false)
            setFileData(undefined)
          }}
          FileData={(e: File) => {
            setFileData(e)
          }}
          onSubmit={EmailUploadL1}
        />
      )}
      {loading && (
        <PopUpStyleLoader>
          <StyledSelectWrapper style={{ flexDirection: 'column' }}>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <LottieWrapper style={{ left: '53.5%' }}> Loading...</LottieWrapper>
          </StyledSelectWrapper>
        </PopUpStyleLoader>
      )}
      {message.length > 0 && (
        <ToastWrapper>
          <Toast
            text={message}
            type={'success'}
            openStatusCallback={() => dispatch(setToastMessage(''))}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default RegSHOCusipSummary
